<div class="body_conatiner">
    <div class="block--header">
        <h1 data-value="Contact us">Contact us</h1>
    </div>
    <p class="sub--head">
        We are rarely offline. We would love to hear from you on anything that bothers you or excites you about our
        product. Drop us a line below and we shall connect to make your business communication a tad better.
    </p>
    <div class="contact--form">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="text-box">
                <input type="email" placeholder="Email" formControlName="email">
                <p class="error-text" *ngIf="submitted && contactForm?.controls?.email?.invalid">Email is required</p>
            </div>
            <div class="text-box">
                <input type="text" placeholder="Subject" formControlName="subject">
                <p class="error-text" *ngIf="submitted && contactForm?.controls?.subject?.invalid">Required</p>
            </div>
            <div class="text-box">
                <textarea placeholder="Message" formControlName="description" maxlength="250px"></textarea>
                <p class="hit-label">Max length - 250 </p>
                <p class="error-text" *ngIf="submitted && contactForm?.controls?.description?.invalid">Required</p>
            </div>
            <div class="form-submit">
                <button class="button-grape">
                    <ng-container *ngIf="!isloading">
                        Submit
                    </ng-container>
                    <div class="three-dot-loading" *ngIf="isloading">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                </button>
            </div>
        </form>
    </div>
</div>