<div class="about-container body_conatiner">
    <div class="about-blocks">
        <div class="main-block">
            <div class="content-b">
                <h3>About Flujo</h3>
                <h1>
                    Building what we
                    Believe in!
                </h1>
            </div>
            <div class="img-block">
                <img src="assets/images/about-us@2x.png" alt="Flujo Group Picture">
            </div>
        </div>
        <div class="about-header-b">
            <h2 class="block-header">
                Vision
            </h2>
            <p class="sub-text">
                We envision a world where everyone can create powerful, flexible websites and apps as easily as they
                create
                documents today. Because the only thing better than the internet is an internet we can all create for.
                <br>
                We envision a world where everyone can create powerful, flexible websites and apps as easily as they
                create
                documents today. Because the only thing better than the internet is an internet we can all create for.
            </p>
        </div>
        <div class="values--section">
            <div class="about-header-b">
                <h2 class="block-header">
                    Values
                </h2>
                <p class="sub-text">
                    We don’t have core values, because values mean nothing without action. So these are our core
                    behaviors.We
                    envision a world where everyone can create powerful, flexible websites and apps as easily as they
                    create
                    documents today. Because the only thing better than the internet is an internet we can all create
                    for.
                </p>
            </div>
            <div class="collapse--menus">
                <ul>
                    <li *ngFor="let panel of panels" [ngClass]="{'open': panel.isOpen}">
                        <div class="active--layer"></div>
                        <div class="menu-header" (click)="togglePanelsMenu(panel)">
                            <h3>{{panel.title}}</h3>
                            <button class="arrow-btn"></button>
                        </div>
                        <div *ngIf="panel.isOpen" class="content-b">
                            <p [innerHtml]="panel.description | htmlSafe">{{panel.description}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="backed-by-block">
            <div class="about-header-b">
                <h2 class="block-header">
                    Backed by
                </h2>
                <p class="sub-text">
                    We don’t have core values, because values mean nothing without action. So these are our core
                    behaviors.We
                    envision a world where everyone can create powerful, flexible websites and apps as easily as they
                    create
                    documents today. Because the only thing better than the internet is an internet we can all create
                    for.
                </p>
            </div>
            <ul>
                <li *ngFor="let item of teamMembers">
                    <div class="picture-b"></div>
                    <div class="details-b">
                        <div>
                            <h4>John Doe</h4>
                            <h5>FOUNDER / CEO</h5>
                        </div>
                        <div class="social-links">
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" id="prefix__linkedin" width="29.851"
                                    height="29.851" viewBox="0 0 29.851 29.851">
                                    <circle id="prefix__Ellipse_537" cx="14.926" cy="14.926" r="14.926"
                                        data-name="Ellipse 537" style="fill:#1d1d1d" />
                                    <g id="prefix__Group_8159" data-name="Group 8159" transform="translate(9.75 8.813)">
                                        <path id="prefix__Path_190809"
                                            d="M38.613 30.732V35h-2.475v-3.982c0-1-.357-1.683-1.253-1.683a1.354 1.354 0 0 0-1.27.9 1.694 1.694 0 0 0-.082.6V35h-2.475s.033-6.746 0-7.444h2.475v1.055c0 .008-.012.016-.016.024h.016v-.024a2.457 2.457 0 0 1 2.231-1.23c1.628 0 2.849 1.064 2.849 3.35zm-10.144-6.763a1.29 1.29 0 1 0-.032 2.572h.016a1.29 1.29 0 1 0 .016-2.572zM27.215 35h2.474v-7.443h-2.474z"
                                            data-name="Path 190809" transform="translate(-27.068 -23.969)"
                                            style="fill:#f1f2f2" />
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="about-numbers-b">
            <h2 class="block-header">
                By the numbers
            </h2>
            <div class="row-b">
                <div>
                    <p class="sub-text">
                        We don’t have core values, because values mean nothing without action. So these are our core
                        behaviors.We envision a world where everyone can create powerful, flexible websites and apps as
                        easily
                        as they create documents today. Because the only thing better than the internet is an internet
                        we
                        can
                        all create for.
                    </p>
                </div>
                <div class="numbers-block">
                    <ul>
                        <li>
                            <h1>30+</h1>
                            <h3>Team members</h3>
                        </li>
                        <li>
                            <h1>2018</h1>
                            <h3>Year founded</h3>
                        </li>
                        <li>
                            <h1>1M+</h1>
                            <h3>Users</h3>
                        </li>
                        <li>
                            <h1>$75M</h1>
                            <h3>In total funding</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="press-block">
            <div class="press-header">
                <h2 class="block-header">
                    In the press
                </h2>
                <button>
                    Download press Kit
                </button>
            </div>
            <ul>
                <li *ngFor="let item of pressNotes">
                    <h3>
                        No-Code Website Builder
                        Webflow Went From Near
                        Bankruptcy To A $72 Million
                        Series A Funding Round
                    </h3>
                    <div class="bottom-b">
                        <p>{{item.name}}</p>
                        <span class="arrow-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.453" height="10.523"
                                viewBox="0 0 14.453 10.523">
                                <g transform="translate(1 1.414)">
                                    <path d="M0 0L11.365 0"
                                        style="fill:none;stroke:#ec316c;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"
                                        transform="translate(0 4.06)" />
                                    <path d="M12 5l3.847 3.847L12 12.695"
                                        style="fill:none;stroke:#ec316c;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"
                                        transform="translate(-3.394 -5)" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>