import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HeroPageComponent } from './new-ui/hero-page/hero-page.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './new-ui/features/features.component';
import { SecurityComponent } from './new-ui/security/security.component';
import { AppDirectoryComponent } from './new-ui/app-directory/app-directory.component';
import { ContactUsComponent } from './new-ui/contact-us/contact-us.component';
import { HelpComponent } from './help/help.component';
import { GoodKarmaComponent } from './new-ui/good-karma/good-karma.component';
import { AboutUsComponent } from './new-ui/about-us/about-us.component';
import { ErrorPageComponent } from './new-ui/error-page/error-page.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  {
    path: '', component: HomeComponent,
    children: [
      { path: '', component: HeroPageComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'features', component: FeaturesComponent },
      { path: 'security', component: SecurityComponent },
      { path: 'appdirectory', component: AppDirectoryComponent },
      { path: 'contactus', component: ContactUsComponent },
      { path: 'help', component: HelpComponent },
      { path: 'goodkarma', component: GoodKarmaComponent },
      // { path: 'aboutus', component: AboutUsComponent }
    ]
  },
  // { path: 'beta', component: BetaPageComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
