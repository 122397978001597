<div class="get-started-c">
    <div>
        <div class="user-pic">
            <img src="assets/images/get-started-block.svg" alt="Get started">
        </div>
        <div>
            <h2>Get started for free</h2>
            <button class="button-grape" (click)="dcService.getstarted()">Try for free</button>
            <ul>
                <li>
                    <span class="check-mark"></span>
                    <p>No credit card required</p>
                </li>
                <li>
                    <span class="check-mark"></span>
                    <p>90-day free trial</p>
                </li>
            </ul>
        </div>
    </div>
</div>