<nav id="nav-bar" [class.isScrolling]="scrollingStart" [ngClass]="{'menuOpen': isSideMenu}">
  <div class="body_conatiner">
    <div class="nav-logo">
      <a routerLink="/">
        <img src="assets/flujo-logo.svg" alt="Flujo Logo">
      </a>
    </div>
    <div *ngIf="!isHambergerMenu" class="nav-right-menu">
      <ul>
        <li>
          <a class="nav--link" routerLinkActive="active" routerLink="/features" (click)="closeMenu()">
            Features
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/pricing">
            Pricing
          </a>
        </li>
        <li>
          <a class="nav--link" href="https://blog.flujo.io/" (click)="closeMenu()">
            Blog
          </a>
        </li>
        <li>
          <a class="nav--link" href="https://intercom.help/flujoapp/en">
            <!-- (click)="helpRedirect()" https://help.flujo.io/hc/en-us-->
            Help
          </a>
        </li>
        <li>
          <a class="nav--link" routerLinkActive="active" (click)=navigateLogin()>
            Login
          </a>
        </li>
      </ul>
      <!-- Get Started  -->
      <div class="get-started">
        <button class="button-grape" (click)="getstarted()">
          Try for free
        </button>
      </div>
    </div>
    <button class="menu--button" (click)="openMenu()">
      <svg *ngIf="!isSideMenu" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
        style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <path d="M491.318,235.318H20.682C9.26,235.318,0,244.577,0,256s9.26,20.682,20.682,20.682h470.636
			c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z" />
        <path d="M491.318,78.439H20.682C9.26,78.439,0,87.699,0,99.121c0,11.422,9.26,20.682,20.682,20.682h470.636
			c11.423,0,20.682-9.26,20.682-20.682C512,87.699,502.741,78.439,491.318,78.439z" />
        <path d="M491.318,392.197H20.682C9.26,392.197,0,401.456,0,412.879s9.26,20.682,20.682,20.682h470.636
			c11.423,0,20.682-9.259,20.682-20.682S502.741,392.197,491.318,392.197z" />
      </svg>
    </button>
  </div>
</nav>

<div *ngIf="isHambergerMenu" class="toggle--header-menu" [ngClass]="{'isShow': isSideMenu}">
  <div class="nav-logo">
    <a routerLink="/">
      <img src="assets/flujo-logo.svg" alt="Flujo Logo">
    </a>
    <button (click)="closeMenu()">
      <svg style="width: 16px;" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 47.971 47.971" xml:space="preserve">
        <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
      </svg>
    </button>
  </div>
  <div class="toggle--body">
    <ul>
      <li>
        <a class="nav--link" routerLinkActive="active" routerLink="/features" (click)="closeMenu()">
          Features
        </a>
      </li>
      <li>
        <a routerLinkActive="active" routerLink="/pricing" (click)="closeMenu()">
          Pricing
        </a>
      </li>
      <li>
        <a class="nav--link" href="https://blog.flujo.io/" (click)="closeMenu()">
          Blog
        </a>
      </li>
      <li>
        <a class="nav--link" (click)="helpRedirect()">
          Help
        </a>
      </li>
      <li>
        <a class="nav--link" routerLinkActive="active" (click)=navigateLogin()>
          Login
        </a>
      </li>
    </ul>
    <div class="actions--btns">
      <button class="download-btn button-grape" (click)="downloadApp()">
        Download
      </button>
      <button class="button-grape">Try for free</button>
    </div>
  </div>
</div>