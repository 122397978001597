import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubmitFormComponent } from './submit-form/submit-form.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  postURL = 'https://delta.flujo.io/contactform/';
  constructor(private http: HttpClient, private dialog: MatDialog) { }
  httpPost(url, data) {
    return this.http.post<any>(url, data);
  }
  openDialog() {
    const dialogRef = this.dialog.open(SubmitFormComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
