<div class="hero--container body_conatiner">
    <div id="scheduleStrip" class="schedule--strip">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.056" height="19.576" viewBox="0 0 15.056 19.576">
                <g transform="translate(-580.096 -208)">
                    <path d="M14.812 29.271c1.464 0 2.651-.905 2.651-2.023h-5.3c-.003 1.118 1.183 2.023 2.649 2.023z"
                        style="fill:#ec316c" transform="translate(572.813 198.305)" />
                    <path
                        d="M19.845 16.746l-1.163-1.514V8.821c0-2.512-1.679-3.646-3.409-4.138a2.787 2.787 0 0 0-2.65-2.86 2.785 2.785 0 0 0-2.648 2.86c-1.731.492-3.41 1.627-3.41 4.138v6.41L5.4 16.746c-.6.786-.287 1.429.7 1.429h13.041c.992 0 1.308-.644.704-1.429zM11.51 4.391a1.224 1.224 0 0 1 1.114-1.067 1.224 1.224 0 0 1 1.115 1.067 10.249 10.249 0 0 0-2.229 0z"
                        style="fill:#ec316c" transform="translate(575 206.177)" />
                </g>
            </svg>
            <p>
                Learn how Flujo can make your teams more productive.
                <!-- We are free till December 2020 due to COVID-19 and Early Signups -->
            </p>
        </div>
        <a href="https://calendly.com/flujoapp/demo" target="_new">Schedule a Demo Now
            <svg style="fill: #ec316c" xmlns="http://www.w3.org/2000/svg" width="10.274" height="7.048"
                viewBox="0 0 10.274 7.048">
                <g>
                    <path d="M6.805 7.048l3.469-3.472L6.7 0l-.707.707L8.86 3.576 6.097 6.341l.708.707z" />
                    <path d="M8.86 3.076H0v1h8.86" />
                </g>
            </svg>
        </a>
    </div>
    <div @pageAnimations class="block--container hero-b">
        <div class="content-b">
            <h1>
                Working remotely?
                We’ve got you covered.
            </h1>
            <p>
                Flujo is a communication & collaboration suite for teams. With
                natively built key business essentials like messaging, AV calling, drive,
                meetings, tasks, calendar, notes, and kanbans, we are on a
                mission to create happy & productive workspaces.
            </p>
            <div class="action--c">
                <button class="button-grape" (click)="dcService$.getstarted()">
                    Try for free
                </button>
            </div>
        </div>
        <div class="img--b">
            <img src="../../../assets/images/hero--page.svg" alt="Working remotely">
        </div>
    </div>

    <!-- Communicate -->
    <div class="block--container block--1">
        <div class="img--b">
            <img class="img--1" src="../../../assets/images/communicate-block.svg" alt="Communication">
        </div>
        <div class="content-b">
            <h3>Communication</h3>
            <h2>
                Organize conversations better!
            </h2>
            <p>
                Stay in sync with your team with our feature rich messenger.
            </p>
            <ul class="cards--list">
                <li>
                    <h3>One-to-one chat & <br> Team chat</h3>
                    <h6>
                        Replace your internal email communication with a powerful, secure, and an easy to use messenger.
                    </h6>
                </li>
                <li>
                    <h3>1:1 audio & video calls, Group conferencing</h3>
                    <h6>
                        Instantly get on a video call or share your screen with anyone.
                    </h6>
                </li>
                <li>
                    <h3>Threads & mentions</h3>
                    <h6>
                        Say goodbye to cluttered inboxes with threads and nudge anyone from anywhere with
                        <span>@mentions</span>.
                    </h6>
                </li>
                <li>
                    <h3>Unlimited message history</h3>
                    <h6>
                        Combined knowledge of your entire organisation— a simple search away
                    </h6>
                </li>
            </ul>
            <div class="action--c">
                <button routerLink="/features" class="button-grape" (click)="gotoFeature(1)">
                    Know more
                </button>
            </div>
        </div>
    </div>

    <!-- Collaborate -->
    <div class="block--container block--1">
        <div class="content-b">
            <h3>Collaboration</h3>
            <h2>
                One place means not all over the place
            </h2>
            <p>
                Give a new dimension to collaboration, quickly share files, assign tasks, set up meetings on the go.
            </p>
            <ul class="cards--list">
                <li>
                    <h3>Storage is on the house</h3>
                    <h6>
                        Store and Collaborate without any limits with a native drive that offers 100GB of storage per
                        user.
                    </h6>
                </li>
                <li>
                    <h3>Never let a meeting invite slip away!</h3>
                    <h6>
                        Create, manage, and modify your meetings in a jiffy — designed to ensure that you never miss an
                        important event.
                    </h6>
                </li>
                <li>
                    <h3>Chasing goals made simple with tasks</h3>
                    <h6>
                        Quickly assign tasks to yourself or your teams and never let something slip through the cracks.
                    </h6>
                </li>
                <li>
                    <h3>Calendar that looks out for you</h3>
                    <h6>
                        A built-in calendar, deeply integrated with our messenger. Visualize and manage all your tasks
                        and meetings like never before.
                    </h6>
                </li>
            </ul>
            <div class="action--c">
                <button routerLink="/features" class="button-grape" (click)="gotoFeature(2)">
                    Know more
                </button>
            </div>
        </div>
        <div class="img--b">
            <img src="../../../assets/images/collaborate-block.svg" alt="Collaboration">
        </div>
    </div>

    <!-- Organise -->
    <div class="block--container block--1">
        <div class="img--b">
            <img src="../../../assets/images/organise-block.svg" alt="Productivity">
        </div>
        <div class="content-b">
            <h3>Productivity</h3>
            <h2>
                Stay on top of your day every day
            </h2>
            <p>
                From paperclips to airplanes, no matter what you make — we’ve got you covered. Sit back and relax while
                <b>tac</b> takes care of the rest.
            </p>
            <ul class="cards--list">
                <li>
                    <h3>Organize your projects with
                        Kanbans</h3>
                    <h6>
                        Plan, Visualise, and Execute your workflows with Kanban. With an agile practice like Kanban in
                        place, your projects will never miss a deadline.
                    </h6>
                </li>
                <li>
                    <h3>tac — your very own executive assistant</h3>
                    <h6>
                        Not all superheroes come with a cape, some come with a tail too! Let <b>tac</b> — your very own
                        workplace bot assistant bring all the important updates to you, while you sit back and relax.
                    </h6>
                </li>
                <li>
                    <h3>Create notes & share them</h3>
                    <h6>
                        Your writing pad at work — Capture all your crazy ideas, minutes of your meetings, briefs, etc.
                        and instantly share with your team.
                    </h6>
                </li>
                <li>
                    <h3>Alerts and reminders</h3>
                    <h6>
                        Rest assured about your tasks and deadlines with timely alerts and reminders— directly brought
                        to you by <b>tac.</b>
                    </h6>
                </li>
            </ul>
            <div class="action--c">
                <button routerLink="/features" class="button-grape" (click)="gotoFeature(3)">
                    Know more
                </button>
            </div>
        </div>
    </div>

    <!-- Integrations -->
    <div class="block--container block--1 block--2">
        <div class="content-b">
            <h3>Integrations</h3>
            <h2>
                Bring apps you love and
                work from a single place.
            </h2>
            <div class="text--b">
                <p>
                    Unleash the power of oneness with our ever-growing pool of integrations. Do more without having to
                    step out of your messenger — now with your favorite apps connected with Flujo.
                </p>
            </div>
            <div class="action--c">
                <button routerLink="/appdirectory" class="button-grape">
                    Know more
                </button>
            </div>
        </div>
        <div class="img--b">
            <img src="../../../assets/images/integrated-icons.png" alt="Integrations">
            <!-- <ul class="integration-list">
                <li>
                    <img src="../../../assets/images/drive@2x.jpg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/git-hub.svg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/bit-bucket.svg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/jenkins.svg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/jitsi@2x.jpg" alt="">
                </li>
                <li>
                    <img src="../../../assets/images/unsplash.svg" alt="">
                </li>
            </ul> -->
        </div>
    </div>

    <!-- Download Block -->
    <div class="block--container download_block">
        <div class="block--container block--1 block--3">
            <div class="img--b">
                <img src="../../../assets/images/wfh-block.svg" alt="Work from home">
            </div>
            <div class="content-b">
                <h3>Work from home</h3>
                <h2>
                    Work seamlessly across
                    Any device
                </h2>
                <ul class="download--list">
                    <li>
                        <span class="check-cirle"></span>
                        <p>Web application</p>
                    </li>
                    <li>
                        <span class="check-cirle"></span>
                        <p>Mac OS <span>(Coming soon)</span></p>
                    </li>
                    <li>
                        <span class="check-cirle"></span>
                        <p>Windows Application <span>(Coming soon)</span></p>
                    </li>
                    <li>
                        <span class="check-cirle"></span>
                        <p>iOS app</p>
                    </li>
                    <li>
                        <span class="check-cirle"></span>
                        <p>Android app</p>
                    </li>
                </ul>
                <!-- <div class="action--c">
                    <button class="button-grape">
                        Get started
                    </button>
                </div> -->
                <ul class="download-links">
                    <li *ngIf="false">
                        <a>
                            <div class="icon--b">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.914" height="35.675"
                                    viewBox="0 0 28.914 35.675">
                                    <g transform="translate(-3.381)">
                                        <path fill="currentColor"
                                            d="M32.295 26.2c-.1.271-.189.562-.3.848a21.982 21.982 0 0 1-3.863 6.481 8.366 8.366 0 0 1-1.061 1.043 4.188 4.188 0 0 1-2.608 1.052 5.229 5.229 0 0 1-2.161-.394c-.5-.205-1-.434-1.5-.619a6.732 6.732 0 0 0-4.92.121c-.586.232-1.164.488-1.761.689a4.209 4.209 0 0 1-2.141.188 4.165 4.165 0 0 1-1.714-.8 9.667 9.667 0 0 1-1.688-1.67 22.81 22.81 0 0 1-4.771-9.906 18.01 18.01 0 0 1-.4-4.812 11.4 11.4 0 0 1 1.607-5.469 8.677 8.677 0 0 1 5.568-4.041 7.086 7.086 0 0 1 4.263.332q1.1.42 2.2.832a2.763 2.763 0 0 0 2.026-.005q1.124-.429 2.251-.85a9.036 9.036 0 0 1 2.378-.573 8.913 8.913 0 0 1 3.58.449 7.843 7.843 0 0 1 4 2.946l.077.113a7.879 7.879 0 0 0-3.794 7.311 7.763 7.763 0 0 0 4.732 6.734zM17.98 8.253a5.448 5.448 0 0 0 2.174-.4 7.916 7.916 0 0 0 4.791-7.2c0-.207-.018-.414-.027-.654-.327.049-.625.072-.911.144a7.961 7.961 0 0 0-5.281 3.961 6.825 6.825 0 0 0-.952 3.957c.008.152.063.19.206.192z" />
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <p>Download for</p>
                                <h3>macOS</h3>
                            </div>
                        </a>
                    </li>
                    <li *ngIf="false">
                        <a>
                            <div class="icon--b">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.18" height="31.106"
                                    viewBox="0 0 33.18 31.106">
                                    <g transform="translate(0 -16)">
                                        <g transform="translate(0 18.333)">
                                            <g>
                                                <path d="M0 53.815v10.368h14.516V52z" style="fill:currentColor"
                                                    transform="translate(0 -52)" />
                                            </g>
                                        </g>
                                        <g transform="translate(16.59 16)">
                                            <g>
                                                <path d="M256 18.074v12.442h16.59V16z" style="fill:currentColor"
                                                    transform="translate(-256 -16)" />
                                            </g>
                                        </g>
                                        <g transform="translate(16.59 32.59)">
                                            <g>
                                                <path d="M256 272v12.442l16.59 2.074V272z" style="fill:currentColor"
                                                    transform="translate(-256 -272)" />
                                            </g>
                                        </g>
                                        <g data-name="Group 13278" transform="translate(0 32.59)">
                                            <g data-name="Group 13277">
                                                <path d="M0 272v10.369l14.516 1.815V272z" style="fill:currentColor"
                                                    transform="translate(0 -272)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <p>Download for</p>
                                <h3>Windows</h3>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="https://app.flujo.io/get-started" target="_blank">
                            <div>
                                <p>Use it on</p>
                                <h3>All browsers</h3>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="https://apps.apple.com/in/app/flujo/id1496570932" target="_blank">
                            <div class="icon--b">
                                <svg xmlns="http://www.w3.org/2000/svg" width="39.055" height="34.589"
                                    viewBox="0 0 39.055 34.589">
                                    <g transform="translate(-890.69 -4553.473)">
                                        <g transform="translate(890.69 4553.473)">
                                            <g>
                                                <path
                                                    d="M110.713 107.128a2.863 2.863 0 0 0-5.245-.69l-.441.763-.441-.763a2.867 2.867 0 0 0-3.911-1.048 2.867 2.867 0 0 0-1.048 3.911l2.094 3.626L94 126.308h-5.637a2.863 2.863 0 1 0 0 5.727h21.745l-3.306-5.727h-6.194l9.819-17.007a2.844 2.844 0 0 0 .286-2.173z"
                                                    style="fill:currentColor" transform="translate(-85.5 -105.006)" />
                                            </g>
                                        </g>
                                        <g transform="translate(911.54 4563.683)">
                                            <g>
                                                <path
                                                    d="M282.889 205.256h-5.632l-6.4-11.09-3.306 5.726 9.942 17.219a2.844 2.844 0 0 0 1.739 1.334 2.879 2.879 0 0 0 .746.1 2.865 2.865 0 0 0 2.475-4.3l-1.886-3.266h2.326a2.863 2.863 0 0 0 0-5.727z"
                                                    style="fill:currentColor"
                                                    transform="translate(-267.548 -194.166)" />
                                            </g>
                                        </g>
                                        <g transform="translate(893.611 4582.791)">
                                            <g>
                                                <path
                                                    d="M111.949 361l-.563.975a2.863 2.863 0 0 0 4.959 2.863l2.216-3.838z"
                                                    style="fill:currentColor" transform="translate(-111.003 -361)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <p>Download from</p>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=io.flujo.app&hl=en" target="_blank">
                            <div class="icon--b">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.32" height="32.926"
                                    viewBox="0 0 29.32 32.926">
                                    <g transform="translate(-.5)">
                                        <path
                                            d="M1.107 18.855A3.011 3.011 0 0 0 .5 20.683v26.845a3.018 3.018 0 0 0 .527 1.716l14.607-15.235zm0 0"
                                            style="fill:currentColor" transform="translate(0 -17.643)" />
                                        <path d="M49.374 14.97l4.739-4.943L37.455.411a3 3 0 0 0-2.326-.3zm0 0"
                                            style="fill:currentColor" transform="translate(-32.402)" />
                                        <path
                                            d="M47.279 276.18l-14.388 15.008a2.988 2.988 0 0 0 .953.157 3.029 3.029 0 0 0 1.518-.411l16.777-9.686zm0 0"
                                            style="fill:currentColor" transform="translate(-30.308 -258.419)" />
                                        <path
                                            d="M287.43 174.206l-4.87-2.812-5.123 5.343 5.243 5.469 4.75-2.743a3.035 3.035 0 0 0 0-5.258zm0 0"
                                            style="fill:currentColor" transform="translate(-259.128 -160.373)" />
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <p>Download from</p>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Download Link -->
        <ul *ngIf="false" class="download-links">
            <li class="empty-div"></li>
            <li *ngIf="false">
                <a>
                    <div class="icon--b">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.914" height="35.675"
                            viewBox="0 0 28.914 35.675">
                            <g transform="translate(-3.381)">
                                <path fill="currentColor"
                                    d="M32.295 26.2c-.1.271-.189.562-.3.848a21.982 21.982 0 0 1-3.863 6.481 8.366 8.366 0 0 1-1.061 1.043 4.188 4.188 0 0 1-2.608 1.052 5.229 5.229 0 0 1-2.161-.394c-.5-.205-1-.434-1.5-.619a6.732 6.732 0 0 0-4.92.121c-.586.232-1.164.488-1.761.689a4.209 4.209 0 0 1-2.141.188 4.165 4.165 0 0 1-1.714-.8 9.667 9.667 0 0 1-1.688-1.67 22.81 22.81 0 0 1-4.771-9.906 18.01 18.01 0 0 1-.4-4.812 11.4 11.4 0 0 1 1.607-5.469 8.677 8.677 0 0 1 5.568-4.041 7.086 7.086 0 0 1 4.263.332q1.1.42 2.2.832a2.763 2.763 0 0 0 2.026-.005q1.124-.429 2.251-.85a9.036 9.036 0 0 1 2.378-.573 8.913 8.913 0 0 1 3.58.449 7.843 7.843 0 0 1 4 2.946l.077.113a7.879 7.879 0 0 0-3.794 7.311 7.763 7.763 0 0 0 4.732 6.734zM17.98 8.253a5.448 5.448 0 0 0 2.174-.4 7.916 7.916 0 0 0 4.791-7.2c0-.207-.018-.414-.027-.654-.327.049-.625.072-.911.144a7.961 7.961 0 0 0-5.281 3.961 6.825 6.825 0 0 0-.952 3.957c.008.152.063.19.206.192z" />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <p>Download for</p>
                        <h3>macOS</h3>
                    </div>
                </a>
            </li>
            <li *ngIf="false">
                <a>
                    <div class="icon--b">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33.18" height="31.106"
                            viewBox="0 0 33.18 31.106">
                            <g transform="translate(0 -16)">
                                <g transform="translate(0 18.333)">
                                    <g>
                                        <path d="M0 53.815v10.368h14.516V52z" style="fill:currentColor"
                                            transform="translate(0 -52)" />
                                    </g>
                                </g>
                                <g transform="translate(16.59 16)">
                                    <g>
                                        <path d="M256 18.074v12.442h16.59V16z" style="fill:currentColor"
                                            transform="translate(-256 -16)" />
                                    </g>
                                </g>
                                <g transform="translate(16.59 32.59)">
                                    <g>
                                        <path d="M256 272v12.442l16.59 2.074V272z" style="fill:currentColor"
                                            transform="translate(-256 -272)" />
                                    </g>
                                </g>
                                <g data-name="Group 13278" transform="translate(0 32.59)">
                                    <g data-name="Group 13277">
                                        <path d="M0 272v10.369l14.516 1.815V272z" style="fill:currentColor"
                                            transform="translate(0 -272)" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>
                        <p>Download for</p>
                        <h3>Windows</h3>
                    </div>
                </a>
            </li>
            <li>
                <a>
                    <div>
                        <p>Use it on</p>
                        <h3>All browsers</h3>
                    </div>
                </a>
            </li>
            <li>
                <a>
                    <div class="icon--b">
                        <svg xmlns="http://www.w3.org/2000/svg" width="39.055" height="34.589"
                            viewBox="0 0 39.055 34.589">
                            <g transform="translate(-890.69 -4553.473)">
                                <g transform="translate(890.69 4553.473)">
                                    <g>
                                        <path
                                            d="M110.713 107.128a2.863 2.863 0 0 0-5.245-.69l-.441.763-.441-.763a2.867 2.867 0 0 0-3.911-1.048 2.867 2.867 0 0 0-1.048 3.911l2.094 3.626L94 126.308h-5.637a2.863 2.863 0 1 0 0 5.727h21.745l-3.306-5.727h-6.194l9.819-17.007a2.844 2.844 0 0 0 .286-2.173z"
                                            style="fill:currentColor" transform="translate(-85.5 -105.006)" />
                                    </g>
                                </g>
                                <g transform="translate(911.54 4563.683)">
                                    <g>
                                        <path
                                            d="M282.889 205.256h-5.632l-6.4-11.09-3.306 5.726 9.942 17.219a2.844 2.844 0 0 0 1.739 1.334 2.879 2.879 0 0 0 .746.1 2.865 2.865 0 0 0 2.475-4.3l-1.886-3.266h2.326a2.863 2.863 0 0 0 0-5.727z"
                                            style="fill:currentColor" transform="translate(-267.548 -194.166)" />
                                    </g>
                                </g>
                                <g transform="translate(893.611 4582.791)">
                                    <g>
                                        <path d="M111.949 361l-.563.975a2.863 2.863 0 0 0 4.959 2.863l2.216-3.838z"
                                            style="fill:currentColor" transform="translate(-111.003 -361)" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>
                        <p>Download from</p>
                        <h3>App Store</h3>
                    </div>
                </a>
            </li>
            <li>
                <a>
                    <div class="icon--b">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29.32" height="32.926"
                            viewBox="0 0 29.32 32.926">
                            <g transform="translate(-.5)">
                                <path
                                    d="M1.107 18.855A3.011 3.011 0 0 0 .5 20.683v26.845a3.018 3.018 0 0 0 .527 1.716l14.607-15.235zm0 0"
                                    style="fill:currentColor" transform="translate(0 -17.643)" />
                                <path d="M49.374 14.97l4.739-4.943L37.455.411a3 3 0 0 0-2.326-.3zm0 0"
                                    style="fill:currentColor" transform="translate(-32.402)" />
                                <path
                                    d="M47.279 276.18l-14.388 15.008a2.988 2.988 0 0 0 .953.157 3.029 3.029 0 0 0 1.518-.411l16.777-9.686zm0 0"
                                    style="fill:currentColor" transform="translate(-30.308 -258.419)" />
                                <path
                                    d="M287.43 174.206l-4.87-2.812-5.123 5.343 5.243 5.469 4.75-2.743a3.035 3.035 0 0 0 0-5.258zm0 0"
                                    style="fill:currentColor" transform="translate(-259.128 -160.373)" />
                            </g>
                        </svg>
                    </div>
                    <div>
                        <p>Download from</p>
                        <h3>Google Play</h3>
                    </div>
                </a>
            </li>
        </ul>
    </div>

    <!-- Updates -->
    <div class="updated--container">
        <h3>Updates</h3>
        <h2>What’s new @flujohq</h2>
        <div class="updated-container">
            <ul class="updates--list">
                <li *ngFor="let item of items">
                    <a href="{{item?.link}}" target="_blank">
                        <div>
                            <div class="blog-pic">
                                <img [src]="item?.jetpack_featured_media_url" alt="{{items?.title?.rendered}}">
                            </div>
                            <h4>
                                {{item?.title?.rendered}}
                            </h4>
                            <h5>{{item?.date | date: 'MMM d, y' }}</h5>
                            <p>
                                {{item?.excerpt?.rendered | plainText}}
                            </p>
                        </div>
                        <div class="blog-footer">
                            <h5>READ ARTICLE</h5>
                            <div class="arrow-btn-right">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.274" height="7.048"
                                    viewBox="0 0 10.274 7.048">
                                    <g data-name="Group 5663">
                                        <path
                                            d="M6.805 7.048l3.469-3.472L6.7 0l-.707.707L8.86 3.576 6.097 6.341l.708.707z"
                                            data-name="Path 190156" />
                                        <path d="M8.86 3.076H0v1h8.86" data-name="Path 190157" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>