<app-header></app-header>
<div class="page--container">
    <img src="assets/illustrations/error-404.svg" alt="Error 404">
    <h2>
        Oops! The page you were looking doesn’t exist
    </h2>
    <p>
        You may have mistyped the address or <br>
        the page might have moved.
    </p>
    <div>
        <button routerLink="/" class="button-grape">Take me back to Home</button>
    </div>
</div>