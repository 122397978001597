import { Directive, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[appVideoViewport]'
})
export class VideoViewportDirective implements OnInit {
	@Input() intersectionThreshold: number | number[];
	@Output() visibilityChange = new EventEmitter<any>();

	constructor(private element: ElementRef) { }

	ngOnInit(): void {
		const element = this.element.nativeElement;
		const config = {
			threshold: this.intersectionThreshold
		};
		const intersectionObserver = new IntersectionObserver(
			(entries, observer) => {
				this.visibilityChange.emit(entries[0]);
				// console.log(entries[0]);
			},
			config
		);
		intersectionObserver.observe(element);
	}

}
