<div class="feature-container body_conatiner">
    <div class="block--header">
        <h1 data-value="Good Karma">Good Karma</h1>
    </div>
    <p class="sub--head">
        We love women entrepreneurs, non-profits & startups.
        Hence we have curated special plans & offers for you.
    </p>
    <div class="tabs-view-block">
        <div *ngIf="!isMobileView" class="tabs--header">
            <ul>
                <li [ngClass]="{'active': activeTabId === 1}">
                    <a (click)="changeTab(1)">Women entrepreneurs </a>
                </li>
                <li [ngClass]="{'active': activeTabId === 2}">
                    <a (click)="changeTab(2)">Non-profits</a>
                </li>
                <li [ngClass]="{'active': activeTabId === 3}">
                    <a (click)="changeTab(3)">Startups</a>
                </li>
            </ul>
        </div>
        <div class="tabs--data-block">
            <div *ngIf="activeTabId === 1 || isMobileView" class="content--b">
                <div class="text--b">
                    <h3 *ngIf="isMobileView">Women entrepreneurs</h3>
                    <p>
                        We love women entrepreneurs. Period.<br>
                        A big shout out to all the strong women out there trying to build a better future.
                    </p>
                </div>
                <div class="img-block">
                    <img style="max-width: 280px;" src="assets/illustrations/girl-power.svg" alt="Girl power">
                </div>
            </div>
            <div *ngIf="activeTabId === 2 || isMobileView" class="content--b">
                <div class="text--b">
                    <h3 *ngIf="isMobileView">Non-profits</h3>
                    <p>
                        There are so many nonprofits, working hard to make the world a better place. We want to help a
                        tiny bit and be part of the change you are trying to bring in.
                    </p>
                    <br>
                    <p>
                        We offer 18 months of free subscription and after that 50% discount for all non-profit
                        organizations.
                    </p>
                    <br>
                    <p>
                        Start signing up, our customer success team will get in touch with you and evaluate your
                        eligibility such as registration documents, etc.
                    </p>
                </div>
                <div class="img-block">
                    <img class="max70" src="assets/illustrations/non-profits.svg" alt="Non-profits">
                </div>
            </div>
            <div *ngIf="activeTabId === 3 || isMobileView" class="content--b startup--b">
                <div class="text--b">
                    <h3 *ngIf="isMobileView">Startups</h3>
                    <p>
                        We understand you. We are a startup and we do know what struggle you go through in building your
                        dreams.
                    </p>
                    <br>
                    <p>
                        We offer 18 months of our pro subscription at zero cost.
                    </p>
                    <br>
                    <p class="h--hdr">Eligibility requirements</p>
                    <ul class="requirement-ul">
                        <li>
                            <p>Early-stage</p>
                        </li>
                        <li>
                            <p>Your startup registered in the last 24 months</p>
                        </li>
                        <li>
                            <p>Received funding less than 1m USD</p>
                        </li>
                        <li>
                            <p>Team size less than or equal to 50</p>
                        </li>
                    </ul>
                </div>
                <div class="img-block">
                    <img src="assets/startups.png" alt="Startups">
                </div>
            </div>
        </div>
    </div>
</div>