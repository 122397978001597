import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  items = [
    { name: 'Hosted in the cloud by Amazon Web Services (AWS)' },
    { name: 'Data Encryption in transit using TLS 1.2 and at rest' },
    { name: 'Advanced security monitoring for responsible disclosure' },
    { name: 'Industry standard data retention and archival policies' },
  ];
  constructor(private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
    }

    this.seoService.updateTags('security');
  }


  


  

}
