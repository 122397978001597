import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  panels = [
    {
      id: 1,
      title: "Default to transparency",
      description: "We don’t have core values, because values mean nothing without action. So these are our corebehaviors.",
      isOpen: false
    },
    {
      id: 2,
      title: "Cultivate positivity",
      description: "We don’t have core values, because values mean nothing without action. So these are our corebehaviors.",
      isOpen: false
    },
    {
      id: 3,
      title: "Show gratitude",
      description: "We don’t have core values, because values mean nothing without action. So these are our corebehaviors.",
      isOpen: false
    },
    {
      id: 4,
      title: "Practice reflection",
      description: "We don’t have core values, because values mean nothing without action. So these are our corebehaviors.",
      isOpen: false
    },
    {
      id: 5,
      title: "Improve consistently",
      description: "We don’t have core values, because values mean nothing without action. So these are our corebehaviors.",
      isOpen: false
    }
  ];

  teamMembers = [
    {},
    {},
    {},
    {},
    {},
  ];

  pressNotes = [
    { name: 'INK TALKS' },
    { name: 'YOURSTORY' },
    { name: 'YOURSTORY' },
    { name: 'YOURSTORY' },
  ]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    // this.setMetaTags();
  }

  togglePanelsMenu(item) {
    this.panels.forEach(el => {
      if (item.id === el.id) {
        item.isOpen = !item.isOpen;
      } else {
        el.isOpen = false
      }
    });
  }

  // setMetaTags() {
  //   this.seoService.updateTitle('');
  //   this.seoService.updateDescription
  //     ('');
  //   this.seoService.updateOgUrl('');
  // }

}
