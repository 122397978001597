<div class="body_conatiner">
	<div class="block--header">
		<h1 data-value="How can we help?">How can we help?</h1>
	</div>
	<p class="sub--head">
		Help Centre , where you will find all your answers. We are constantly updating our help center. Contact us for
		any queries
	</p>
	<div class="search-menu-header">
		<h2>Categories</h2>
		<div class="search--block">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.785" height="16.785" viewBox="0 0 16.785 16.785">
				<g transform="translate(.5 .5)">
					<circle cx="7" cy="7" r="7"
						style="fill:none;stroke:#666;stroke-linecap:round;stroke-linejoin:round" />
					<path d="M3.663 3.663L0 0" style="fill:none;stroke:#666;stroke-linecap:round;stroke-linejoin:round"
						transform="translate(11.915 11.915)" />
				</g>
			</svg>
			<input #searchInput (input)="searchApp(searchInput.value)" type="text" placeholder="Search by app name">
		</div>
	</div>
	<div class="categories-list">
		<ul>
			<li *ngFor="let item of categoriesList">
				<a target="_blank">
					<div class="icon-b">
						<img src="assets/icons/{{item.icon}}" alt="{{item.alt}}">
					</div>
					<h3>{{item.name}}</h3>
				</a>
			</li>
		</ul>
	</div>
	<div class="expansion--menu--block">
		<h2>Frequently asked questions</h2>
		<ul>
			<li *ngFor="let panel of panels" [ngClass]="{'open': panel.isOpen}">
				<div class="menu-header" (click)="toggleMenu(panel)">
					<h3>{{panel.title}}</h3>
					<span class="icon--plus"></span>
				</div>
				<div *ngIf="panel.isOpen" class="content-b">
					<p>{{panel.content}}</p>
				</div>
			</li>
		</ul>
	</div>
</div>