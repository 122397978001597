import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { WindowSizeService } from 'src/app/_services/window-size.service';
import { Subscription } from 'rxjs';
import { DataCommunicationService } from 'src/app/_services/data-communication.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-good-karma',
  templateUrl: './good-karma.component.html',
  styleUrls: ['./good-karma.component.scss']
})
export class GoodKarmaComponent implements OnInit, OnDestroy {
  activeTabId = 1;
  isMobileView: boolean;
  mobileViewObserver: Subscription;
  activeTabObserver: Subscription;

  constructor(private windowSizeService: WindowSizeService, private dcService$: DataCommunicationService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.windowSizeCheck();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.activeTabListener();
  }
  ngOnDestroy() {
    if(this.mobileViewObserver){
      this.mobileViewObserver.unsubscribe();
    }
    if(this.activeTabObserver){
      this.activeTabObserver.unsubscribe();
    }
    this.dcService$.$activeKarma.next(null);
  }

  windowSizeCheck() {
    this.mobileViewObserver = this.windowSizeService.$mobileView.subscribe(res => {
      this.isMobileView = res;
    });
  }

  changeTab(id) {
    this.activeTabId = id;
  }

  activeTabListener() {
    this.activeTabObserver = this.dcService$.$activeKarma.subscribe(res => {
      if (res) {
        try {
          setTimeout(() => {
            if (this.isMobileView) {
              const element = document.querySelector(`.tabs--data-block .content--b:nth-child(${res})`);
              const headerOffset = 90;
              const elementPosition = element.getBoundingClientRect().top;
              const offsetPosition = elementPosition - headerOffset;
              if (isPlatformBrowser(this.platformId)) {
                window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth"
                });
              }
            } else {
              this.changeTab(res);
              const element = document.querySelector('.tabs--header');
              const headerOffset = 110;
              const elementPosition = element.getBoundingClientRect().top;
              const offsetPosition = elementPosition - headerOffset;
              if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
              });
            }
            }
          }, 10);
        } catch (error) {
          console.log(error);
        }
      }
    })
  }

}
