<footer id="footer-block">
  <div class="footer-block">
    <div class="body_conatiner" [ngClass]="{'size--md': isTabWidth}">
      <div class="footer-navlogo">
        <div class="msg--b">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="249.941" height="102.459" viewBox="0 0 249.941 102.459">
            <path id="prefix__Union_2"
              d="M13518.038 15338.445h-16.538a10 10 0 0 1-10-10v-62.105a10 10 0 0 1 10-10h229.949a10 10 0 0 1 10 10v62.105a10 10 0 0 1-10 10h-193.061l-19.479 19.477-.867.869z"
              data-name="Union 2" transform="translate(-13491.499 -15256.332)" style="fill:#ec316c" />
          </svg> -->
          <p>
            Hi👋 Iam tac , <br>
            Learn more about our <a routerLink="/features">features.</a>
          </p>
        </div>
        <a>
          <!-- <img src="assets/footer_logo.svg" alt="FLUJO Logo"> -->
          <img src="../../assets/images/footer-block.svg" alt="Flujo tac">
        </a>
      </div>
      <!-- <div class="producthunt-badge">
        <a href='https://www.remote.tools/product/flujo?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-featured'
          target='_blank'><img src='https://remote-tools.s3-us-west-2.amazonaws.com/rt_badge/1/Light.svg' alt='Flujo'
            width='250px' height='54px' /></a>
        <a href="https://www.producthunt.com/posts/flujo-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flujo-2"
          target="_blank"><img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=231079&theme=light"
            alt="Flujo - Communication and Collaboration Suite for Teams | Product Hunt Embed" width="250px"
            height="54px" /></a>
      </div> -->
      <div class="footer-links">
        <ul>
          <h5>Product</h5>
          <li *ngFor="let item of productLinks">
            <a routerLink="{{item.link}}">
              {{item.name}}
            </a>
            <span class="text--red" *ngIf="item?.label">{{item.label}}</span>
          </li>
        </ul>
        <ul>
          <h5>
            Resources
          </h5>
          <li *ngFor="let item of resourcesLinks">
            <a *ngIf="item?.href" href="{{item?.href}}">
              {{item.name}}
            </a>
            <a *ngIf="!item?.href" routerLink="{{item.link}}">
              {{item.name}}
            </a>
          </li>
        </ul>
        <ul class="last--ul">
          <h5>
            Company
          </h5>
          <li *ngFor="let item of companyLinks">
            <a *ngIf="item?.href && !item?.self" href="{{item?.href}}" target="_new">
              {{item.name}}
            </a>
            <a *ngIf="item?.href && item?.self" href="{{item?.href}}">
              {{item.name}}
            </a>
            <a *ngIf="!item?.href" routerLink="{{item.link}}">
              {{item.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="body_conatiner">
      <div>
        <h6>
          All Rights Reserved &copy; Flujo
        </h6>
        <h6>Flujo runs on Flujo</h6>
      </div>
      <div>
        <h6 class="remotely-at">
          Made with <span class="heart-icon"></span> remotely in <span class="location-b">&nbsp;{{activeArea}}.</span>
        </h6>
      </div>
      <div class="res_social_icons">
        <ul>
          <li>
            <a class="sc-link facebook_link" href="https://www.facebook.com/flujohq/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="5.473" height="11.872" viewBox="0 0 5.473 11.872">
                <path id="prefix__Path_190808" fill="#fff"
                  d="M43.065 27.688h-1.623v5.948h-2.46v-5.948h-1.17V25.6h1.17v-1.355a2.306 2.306 0 0 1 2.482-2.482l1.822.008V23.8h-1.323a.5.5 0 0 0-.522.57v1.23h1.839z"
                  data-name="Path 190808" transform="translate(-37.812 -21.763)" />
              </svg>
            </a>
          </li>
          <li>
            <a class="sc-link twitter_link" target="_blank" href="https://twitter.com/flujohq">
              <svg xmlns="http://www.w3.org/2000/svg" id="prefix__Group_8160" width="12.261" height="9.965"
                data-name="Group 8160" viewBox="0 0 12.261 9.965">
                <path id="prefix__Path_190810" fill="#f1f2f2"
                  d="M37.364 35.208a5.019 5.019 0 0 1-1.445.4 2.522 2.522 0 0 0 1.106-1.391 5.036 5.036 0 0 1-1.6.61 2.518 2.518 0 0 0-4.287 2.295 7.141 7.141 0 0 1-5.185-2.629 2.518 2.518 0 0 0 .778 3.359 2.5 2.5 0 0 1-1.139-.315v.032a2.517 2.517 0 0 0 2.018 2.466 2.525 2.525 0 0 1-1.136.044 2.518 2.518 0 0 0 2.35 1.747 5.078 5.078 0 0 1-3.724 1.036 7.154 7.154 0 0 0 11.013-6.027q0-.164-.007-.325a5.1 5.1 0 0 0 1.255-1.3z"
                  data-name="Path 190810" transform="translate(-25.103 -34.028)" />
              </svg>
            </a>
          </li>
          <li>
            <!-- <a target="_blank" href="" class="linkedin-icon"></a> -->
            <a class="sc-link linkedin_link" href="https://www.linkedin.com/company/flujohq/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" id="prefix__Group_8159" width="11.545" height="11.032"
                data-name="Group 8159" viewBox="0 0 11.545 11.032">
                <path id="prefix__Path_190809" fill="#f1f2f2"
                  d="M38.613 30.732V35h-2.475v-3.982c0-1-.357-1.683-1.253-1.683a1.354 1.354 0 0 0-1.27.9 1.694 1.694 0 0 0-.082.6V35h-2.475s.033-6.746 0-7.444h2.475v1.055c0 .008-.012.016-.016.024h.016v-.024a2.457 2.457 0 0 1 2.231-1.23c1.628 0 2.849 1.064 2.849 3.35zm-10.144-6.763a1.29 1.29 0 1 0-.032 2.572h.016a1.29 1.29 0 1 0 .016-2.572zM27.215 35h2.474v-7.443h-2.474z"
                  data-name="Path 190809" transform="translate(-27.068 -23.969)" />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/flujohq/" target="_blank" class="insta-icon instagram_link">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.609" height="13.611" viewBox="0 0 13.609 13.611">
                <g transform="translate(-.449)">
                  <path
                    d="M14.023 4a4.973 4.973 0 0 0-.316-1.651 3.487 3.487 0 0 0-1.992-1.99 4.986 4.986 0 0 0-1.651-.316C9.335.008 9.1 0 7.256 0S5.177.008 4.451.04A4.975 4.975 0 0 0 2.8.356a3.321 3.321 0 0 0-1.207.787 3.351 3.351 0 0 0-.784 1.2A4.987 4.987 0 0 0 .492 4c-.035.729-.043.96-.043 2.808s.008 2.079.04 2.805a4.973 4.973 0 0 0 .316 1.651A3.486 3.486 0 0 0 2.8 13.255a4.987 4.987 0 0 0 1.651.316c.726.032.957.04 2.805.04s2.079-.008 2.805-.04a4.972 4.972 0 0 0 1.651-.316 3.481 3.481 0 0 0 1.988-1.992 4.99 4.99 0 0 0 .316-1.651c.032-.726.04-.957.04-2.805S14.055 4.728 14.023 4zM12.8 9.559a3.731 3.731 0 0 1-.234 1.263 2.258 2.258 0 0 1-1.292 1.292 3.744 3.744 0 0 1-1.263.234c-.718.032-.933.04-2.749.04s-2.034-.008-2.749-.04a3.729 3.729 0 0 1-1.263-.234 2.094 2.094 0 0 1-.782-.508 2.116 2.116 0 0 1-.508-.782 3.745 3.745 0 0 1-.234-1.263c-.032-.718-.04-.933-.04-2.749s.008-2.034.04-2.749A3.729 3.729 0 0 1 1.957 2.8a2.069 2.069 0 0 1 .511-.782 2.113 2.113 0 0 1 .782-.508 3.747 3.747 0 0 1 1.263-.234c.718-.032.933-.04 2.749-.04s2.034.008 2.749.04a3.731 3.731 0 0 1 1.263.234 2.093 2.093 0 0 1 .782.508 2.116 2.116 0 0 1 .508.782 3.746 3.746 0 0 1 .236 1.263c.032.718.04.933.04 2.749s-.011 2.029-.04 2.747zm0 0"
                    style="fill:#fff" />
                  <path
                    d="M128.446 124.5a3.5 3.5 0 1 0 3.5 3.5 3.5 3.5 0 0 0-3.5-3.5zm0 5.765a2.268 2.268 0 1 1 2.268-2.265 2.268 2.268 0 0 1-2.268 2.265zm0 0"
                    style="fill:#fff" transform="translate(-121.19 -121.19)" />
                  <path d="M364.082 89.418a.816.816 0 1 1-.816-.816.816.816 0 0 1 .816.816zm0 0" style="fill:#fff"
                    transform="translate(-352.375 -86.246)" />
                </g>
              </svg>
            </a>
          </li>
          <!-- <li>
            <a target="_blank" href="https://www.youtube.com/channel/UC1s0lk433NuOXEytKBuG4-A" class="youtube-icon"></a>
          </li> -->
          <li>
            <a target="_blank" href="https://medium.com/flujoapp" class="medium_link">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.658" height="10.843" viewBox="0 0 13.658 10.843">
                <g id="prefix__medium-seeklogo_com" transform="translate(-3.249 -4.605)">
                  <path id="prefix__Path_199305" fill="#fff"
                    d="M42.317 59.976a.529.529 0 0 0-.172-.446l-1.275-1.536v-.229h3.96l3.061 6.712 2.691-6.712h3.775v.229l-1.09 1.045a.319.319 0 0 0-.121.306v7.681a.319.319 0 0 0 .121.306l1.065 1.045v.229h-5.358v-.229l1.1-1.071c.108-.108.108-.14.108-.306v-6.208l-3.067 7.789H46.7l-3.571-7.789v5.221a.72.72 0 0 0 .2.6l1.435 1.74v.229H40.7v-.229l1.435-1.74a.7.7 0 0 0 .185-.6z"
                    data-name="Path 199305" transform="translate(-37.448 -53.159)" />
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>