<div class="success--form">
  <button class="close--button" (click)="onNoClick()">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 47.971 47.971" style="enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
      <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
    </svg>
  </button>
  <h2>
    Thanks for letting us in.
  </h2>
  <h3>
    Tell your world about us
  </h3>
  <div class="share--buttons">
    <a href="https://www.facebook.com/dialog/share?app_id=966242223397117&display=popup&href=https%3A%2F%2Fwww.flujo.io%2F" class="-facebook" target="_blank">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="14.878" height="28.416" viewBox="0 0 14.878 28.416">
          <path
            d="M-436.346 967.906h3.013v-4.735h-4.515a6.12 6.12 0 0 0-4.648 2.282s-1.232 1.162-1.251 4.565v3.552h-4.463v5.03h4.463v12.99h5.147V978.6h4.428l.617-5.028h-5.045v-3.552a2.065 2.065 0 0 1 2.254-2.114z"
            style="fill: #fff" transform="translate(448.211 -963.171)" />
        </svg>
      </span>
      Share
    </a>
    <a href="https://twitter.com/intent/tweet?text=Introducing+FLUJO.%0AA+productivity+multiplying+platform+for+businesses+which+brings+together+communication%2C++collaboration.+Register+for+%40flujoapp+early+access+program+at+www.flujo.io" class="-twitter" target="_blank">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="22.744" height="18.485" viewBox="0 0 22.744 18.485">
          <path id="Path_190516"
            d="M-483.926 1214.158a13.188 13.188 0 0 0 13.277-13.278c0-.2 0-.4-.014-.6a9.462 9.462 0 0 0 2.327-2.417 9.35 9.35 0 0 1-2.679.735 4.694 4.694 0 0 0 2.052-2.581 9.393 9.393 0 0 1-2.963 1.134 4.658 4.658 0 0 0-3.406-1.475 4.666 4.666 0 0 0-4.665 4.665 4.769 4.769 0 0 0 .12 1.066 13.259 13.259 0 0 1-9.619-4.876 4.658 4.658 0 0 0-.632 2.344 4.662 4.662 0 0 0 2.077 3.885 4.628 4.628 0 0 1-2.114-.584v.06a4.668 4.668 0 0 0 3.742 4.575 4.681 4.681 0 0 1-1.228.165 4.614 4.614 0 0 1-.878-.086 4.672 4.672 0 0 0 4.359 3.243 9.357 9.357 0 0 1-5.8 2 9.484 9.484 0 0 1-1.113-.065 13.2 13.2 0 0 0 7.153 2.1"
            style="fill: #fff" transform="translate(491.079 -1195.673)" />
        </svg>
      </span>
      Tweet
    </a>
    <a href="mailto:?&subject=&body=Introducing FLUJO. Introducing FLUJO.A productivity multiplying platform for businesses which brings together communication,  collaboration. Register for @flujoapp early access program at www.flujo.io" class="-email">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="19.887" height="15.29" viewBox="0 0 19.887 15.29">
          <g id="Group_7301" data-name="Group 7301" transform="translate(10593.271 -489.952)">
            <path id="Path_190517"
              d="M241.465 1565.456h7.613a4.378 4.378 0 0 1 .965.107 1.765 1.765 0 0 1 1.255 1.095.631.631 0 0 1-.264.9c-.436.284-.882.551-1.321.829q-3.881 2.461-7.758 4.928a.835.835 0 0 1-1.01.006q-4.521-2.885-9.054-5.752c-.41-.26-.463-.42-.293-.873a1.794 1.794 0 0 1 1.769-1.216c1.138-.036 2.277-.03 3.415-.034h4.685z"
              style="fill:#fff;" data-name="Path 190517" transform="translate(-10824.773 -1075.499)" />
            <path id="Path_190518"
              d="M241.354 1748.219c-2.578 0-5.156.006-7.734-.007a3.546 3.546 0 0 1-1.05-.162 1.674 1.674 0 0 1-1.092-1.191.485.485 0 0 1 .244-.663q3.558-2.241 7.119-4.478a.742.742 0 0 1 .829.007q.647.389 1.279.8a.623.623 0 0 0 .846.009c.47-.306.945-.6 1.416-.907a.576.576 0 0 1 .681.036q1.344.862 2.693 1.716 2.122 1.341 4.249 2.676c.536.337.615.611.312 1.172a1.8 1.8 0 0 1-1.619.964c-1.194.037-2.39.028-3.586.031h-4.587z"
              style="fill:#fff;" data-name="Path 190518" transform="translate(-10824.705 -1242.986)" />
            <path id="Path_190519" d="M232.2 1646.641v-8.179l6.425 4.088z" style="fill:#fff;" data-name="Path 190519"
              transform="translate(-10825.429 -1144.946)" />
            <path id="Path_190520" d="M506.689 1642.728l6.407-4.079v8.158z" style="fill:#fff;" data-name="Path 190520"
              transform="translate(-11086.522 -1145.124)" />
          </g>
        </svg>
      </span>
      Mail
    </a>
  </div>
</div>