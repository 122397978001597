import { Injectable } from '@angular/core';
import { ApiConfig, UrlConfig } from './config';
import { HttpClient } from '@angular/common/http';
import { BlogsData } from './blogs-data-models';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private httpClient: HttpClient) { }

  postData(data) {
    return this.httpClient.post<any>(ApiConfig.HOST + UrlConfig.POSTEMAIL, data);
  }

  getBlogPosts() {
    https://example.com/wp-json/wp/v2/categories?exclude=26995,41088
    return this.httpClient.get('https://blog.flujo.io/wp-json/wp/v2/posts');
    // return this.httpClient.get('https://blog.flujo.io/wp-json/wp/v2/posts?categories_exclude=12&per_page=3');
  }

}
