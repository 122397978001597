<div class="feature-container body_conatiner">
    <div class="block--header">
        <h1 data-value="Features">Features</h1>
    </div>
    <p class="sub--head">
        Whether you're a chain of grocery stores in a suburb or a creative agency with offices spread across continents.
        We probably have everything you and your teams need for better communication, seamless collaboration and staying
        productive.
    </p>
    <div #featureDataBlock class="tabs-view-block">
        <div *ngIf="isFeatureTabSticky" class="hidden-div"></div>
        <div class="tabs--header" [ngClass]="{'sticky--header': isFeatureTabSticky}">
            <ul>
                <li [ngClass]="{'active': activeTabId === 1}">
                    <a (click)="changeTab(1, $event)">Communication</a>
                </li>
                <li [ngClass]="{'active': activeTabId === 2}">
                    <a (click)="changeTab(2, $event)">Collaboration</a>
                </li>
                <li [ngClass]="{'active': activeTabId === 3}">
                    <a (click)="changeTab(3, $event)">Productivity</a>
                </li>
            </ul>
        </div>
        <div class="tabs--data-block">
            <div *ngIf="activeTabId === 1">
                <div>
                    <div class="header--b">
                        <h6>Messenger</h6>
                        <h2>
                            Going forward, no back and forth.
                        </h2>
                        <p>
                            Collaborate as you communicate at work. Experience the real power of getting work done with
                            our
                            Messenger.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="messenger-img-b">
                            <div>
                                <!-- <img src="assets/images/mobile-frame.png" alt=""> -->
                                <video #mobileVideo appVideoViewport [intersectionThreshold]="0.5"
                                    (visibilityChange)="onVisibilityChanged($event)">
                                    <source src="assets/videos/loopiphone2.mp4" type="video/mp4">
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </div>
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(communicationItems, item)"
                                [ngClass]="{'open': communicationItems?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of communicationItems.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="communicationItems?.activeId === item.id">
                                    <p [innerHtml]="item?.content | htmlSafe">
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Calls</h6>
                        <h2>
                            Make your meetings more Personal
                        </h2>
                        <p>
                            Not just emails, we thought of replacing your desk phone, too!
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="align-center" style="text-align: center;">
                            <img style="max-width: 80%;" src="assets/illustrations/callsflattened.svg"
                                alt="Callsflattened">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(callItems, item)"
                                [ngClass]="{'open': callItems?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of callItems.items">
                                <div class="card-header">
                                    <div [ngClass]="{'icon-md': item?.isMdIcon}" class="icon"><img
                                            src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="callItems?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pt-100">
                    <div class="header--b">
                        <h6>Polls <span>(Coming soon)</span></h6>
                        <h2>
                            When in doubt, take a poll!
                        </h2>
                        <p>
                            Red or white? Matte or Glossy? Ultra Light or Bold — well, you get the point!
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="align-center" style="text-align: center;">
                            <img style="max-width: 80%;" src="assets/illustrations/polls-illustration.svg"
                                alt="Polls Illustration">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(pollsItems, item)"
                                [ngClass]="{'open': pollsItems?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of pollsItems.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="pollsItems?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div *ngIf="activeTabId === 2">
                <div>
                    <div class="header--b">
                        <h6>Drive</h6>
                        <h2>
                            Store anything, share everything
                        </h2>
                        <p>
                            Never let being low on storage distract you from doing what you do the best.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center" style="text-align: center;">
                            <img src="../../../assets/illustrations/drive.svg" alt="Drive">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(driveCards, item)"
                                [ngClass]="{'open': driveCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of driveCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="driveCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Meetings</h6>
                        <h2>
                            Break the cycle of bad meetings
                        </h2>
                        <p>
                            We have worked really hard to make sure missing a meeting is not easy.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center">
                            <img class="meeting--img" src="../../../assets/illustrations/meetings-illustration.svg"
                                alt="Meetings Illustration">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(meetingsCards, item)"
                                [ngClass]="{'open': meetingsCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of meetingsCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="meetingsCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Tasks <span>(Coming soon)</span></h6>
                        <h2>
                            Put your ideas to task
                        </h2>
                        <p>
                            Sometimes, all it takes to complete an assignment is a gentle nudge.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center">
                            <img class="task--illustration" src="../../../assets/illustrations/tasks.svg" alt="Task">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(tasksCards, item)"
                                [ngClass]="{'open': tasksCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of tasksCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="tasksCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Home</h6>
                        <h2>
                            We didn’t reinvent the calendar,
                            just changed the name—we call it HOME.
                        </h2>
                        <p>
                            Quick glimpse of an entire day, just a click away.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center">
                            <img src="../../../assets/illustrations/calendar.svg" alt="">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(calendarCards, item)"
                                [ngClass]="{'open': calendarCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of calendarCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="calendarCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="activeTabId === 3">
                <div>
                    <div class="header--b">
                        <h6>Kanbans</h6>
                        <h2>
                            Let the cards guide you.
                        </h2>
                        <p>
                            Plan, organize, collaborate effectively with Kanbans
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center task--illustration">
                            <img src="../../../assets/illustrations/kanbans.svg" alt="Kanbans">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(kanbansCards, item)"
                                [ngClass]="{'open': kanbansCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of kanbansCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="kanbansCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Notes</h6>
                        <h2>
                            Your writing pad at work
                        </h2>
                        <p>
                            Eureka moments, quick list of todo's, minutes of meetings, or those phone numbers and
                            addresses you sometimes have to quickly jot down—one place to rest them all.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center">
                            <img src="../../../assets/illustrations/notes.svg" alt="">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(notesCards, item)"
                                [ngClass]="{'open': notesCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of notesCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="notesCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-100">
                    <div class="header--b">
                        <h6>Bot</h6>
                        <h2>
                            Tac, your executive assistant
                        </h2>
                        <p>
                            Your very own executive bot assistant—when it comes to loyalty, we’ve got the best in the
                            industry.
                        </p>
                    </div>
                    <div class="feature-content-b">
                        <div class="img-block align-center">
                            <img class="ml-0-md" style="margin-left: -26%;" src="../../../assets/illustrations/bot.svg"
                                alt="Bot">
                        </div>
                        <div class="feature-cards-container">
                            <div (click)="showContent(botsCards, item)"
                                [ngClass]="{'open': botsCards?.activeId === item?.id}" class="feature-card"
                                *ngFor="let item of botsCards.items">
                                <div class="card-header">
                                    <div class="icon"><img src="assets/icons/{{item?.icon}}" alt="{{item?.icon}}"></div>
                                    <h4>{{item.name}}</h4>
                                </div>
                                <div class="content-b" *ngIf="botsCards?.activeId === item?.id">
                                    <p>
                                        {{item?.content}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>