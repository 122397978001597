<div class="body_conatiner">
    <div class="block--header">
        <h1 data-value="App Directory">App Directory</h1>
    </div>
    <!-- <p class="sub--head">
        Help Centre , where you will find all your answers.
    </p> -->
    <p class="sub--head">
        Unleash the power of oneness with our ever-growing pool of integrations. Do more without having to step out of
        your messenger.
    </p>
    <div class="search-menu-header">
        <h2>Integrations</h2>
        <div class="search--block">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.785" height="16.785" viewBox="0 0 16.785 16.785">
                <g transform="translate(.5 .5)">
                    <circle cx="7" cy="7" r="7"
                        style="fill:none;stroke:#666;stroke-linecap:round;stroke-linejoin:round" />
                    <path d="M3.663 3.663L0 0" style="fill:none;stroke:#666;stroke-linecap:round;stroke-linejoin:round"
                        transform="translate(11.915 11.915)" />
                </g>
            </svg>
            <input #searchInput (input)="searchApp(searchInput.value)" type="text" placeholder="Search by app name">
        </div>
    </div>
    <div class="integrations--list">
        <ul>
            <li *ngFor="let item of integrations">
                <div class="data-block">
                    <div class="icon-b">
                        <img src="assets/images/{{item.icon}}" alt="{{item.name}}">
                    </div>
                    <div class="text-b">
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>