import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plainText'
})
export class PlainTextPipe implements PipeTransform {

  transform(value: string): any {
    const ele = document.createElement('div');
    ele.innerHTML = value;
    return ele.innerText;
  }

}
