import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/http.service';
import { SeoService } from 'src/app/seo.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  isSuccessText = false;
  isloading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private httpSerive: HttpService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
    }
    // Registration Form
    this.contactForm = this.formBuilder.group({
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      description: ['', Validators.required]
    });

    this.seoService.updateTags('contactus');
  }


  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }
  // Form Submit
  onSubmit() {
    this.isloading = true;
    this.submitted = true;
    if (this.contactForm.invalid) {
      this.isloading = false;
      return;
    }
    console.log(this.contactForm.value);
    // https://delta.flujo.io/contactform/
    let url = '';
    // let url = 'https://app.flujo.io/accounts/contactus';
    // let url = 'http://192.168.10.22:3000/contactus';
    this.httpSerive.httpPost(url, this.contactForm.value).subscribe(
      res => {
        if (!res.error) {
          console.log(this.contactForm.value);
          this.submitted = false;
          this.contactForm.reset();
          console.log('Form Successfully Submited...');
          this.isSuccessText = true;
          this.isloading = false;
        } else {
          this.isloading = false;
          console.log('Form not Submited');
        }
      },
      err => {
        this.isloading = false;
        console.log(err);
      }
    );
  }

  

  submitData() {
    this.isloading = false;
  }
}
