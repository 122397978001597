import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  seo = [
    {
      route: '',
      url: 'https://www.flujo.io',
      title: 'Team Communication and Collaboration App | Flujo',
      description: 'Flujo is a business communication app built with key business essentials like messaging, kanbans, drive, meetings, tasks, notes & more to create a happy & productive workspace.',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    },
    {
      route: 'features',
      url: 'https://www.flujo.io/features',
      title: 'Business Communication and Collaboration Tool | Flujo',
      description: 'Flujo probably has everything you and your teams need for better communication, seamless collaboration and staying productive wherever you are',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    },
    {
      route: 'pricing',
      url: 'https://www.flujo.io/pricing',
      title: 'Team Communication App- 90 days free trail | Flujo',
      description: 'Who says freedom comes at a price? Unleash the productivity of your teams with a no strings attached 90-day trial.',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    },
    {
      route: 'security',
      url: 'https://www.flujo.io/security',
      title: 'Trust Flujo to keep your data secure & meet your compliance requirements',
      description: 'As a platform that is entrusted with preserving an organization’s knowledge, communication & data—we are committed to protecting users’ data from unauthorized access',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    },
    {
      route: 'appdirectory',
      url: 'https://www.flujo.io/appdirectory',
      title: 'All in one collaboration tool for teams- App Integrations | Flujo',
      description: 'Unleash the power of oneness with our ever-growing pool of integrations. Do more without having to step out of your messenger. #1 Team Communication App.',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    },
    {
      route: 'contactus',
      url: 'https://www.flujo.io/contactus',
      title: 'Contact flujoapp workspace for any queries| 24/7',
      description: 'We are rarely offline. We would love to hear from you on anything that bothers you or excites you about our product.',
      image: 'https://www.flujo.io/assets/logo.png',
      "image:type": 'image/png',
      "image:width": '300',
      "image:height": '300',
    }
  ];
  constructor(private title: Title, private meta: Meta) { }
  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'title', content: title });
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ name: 'twitter:url', content: url });
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc });
  }
  updateTags = (route: string) => {
    let index = this.seo.findIndex(item => item.route === route);
    if (index !== -1) {
      this.title.setTitle(this.seo[index].title);
      this.meta.updateTag({ name: 'title', content: this.seo[index].title });
      this.meta.updateTag({ property: 'og:title', content: this.seo[index].title });
      this.meta.updateTag({ name: 'twitter:title', content: this.seo[index].title });
      this.meta.updateTag({ property: 'og:url', content: this.seo[index].url });
      this.meta.updateTag({ name: 'twitter:url', content: this.seo[index].url });
      this.meta.updateTag({ name: 'description', content: this.seo[index].description });
      this.meta.updateTag({ property: 'og:description', content: this.seo[index].description });
      this.meta.updateTag({ name: 'twitter:description', content: this.seo[index].description });
    } else{
      this.title.setTitle(this.seo[0].title);
      this.meta.updateTag({ name: 'title', content: this.seo[0].title });
      this.meta.updateTag({ property: 'og:title', content: this.seo[0].title });
      this.meta.updateTag({ name: 'twitter:title', content: this.seo[0].title });
      this.meta.updateTag({ property: 'og:url', content: this.seo[0].url });
      this.meta.updateTag({ name: 'twitter:url', content: this.seo[0].url });
      this.meta.updateTag({ name: 'description', content: this.seo[0].description });
      this.meta.updateTag({ property: 'og:description', content: this.seo[0].description });
      this.meta.updateTag({ name: 'twitter:description', content: this.seo[0].description });
    }
  }
}
