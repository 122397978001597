import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../http.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  existingMail: boolean;
  isLoading: boolean;
  isPartnership: boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpSerive: HttpService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log(router.url);
        if (router.url === '/partnerships') {
          this.isPartnership = true;
        } else {
          this.isPartnership = false;
        }
        this.updatingCanonicalTags(router.url);
      }
    })
  }

  ngOnInit() {
    // this.router.navigate(['/home/hero']);
    if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
    }
    // Registration Form
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.route.params.subscribe(params => {
      // this.activeId = +params['id'];
      // this.activeId = params.id;
      console.log(params);
    });
  }

  updatingCanonicalTags(title) {
    const list: any = document.querySelectorAll('link[rel=canonical]');
    if (list.length > 0) {
      list[list.length - 1].href = 'https://www.flujo.io' + title;
    } else {
      this.createCanonicalURL(title);
    }
  }

  createCanonicalURL(title) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', 'https://www.flujo.io' + title);
  }


  getstarted() {
    try {
      (window as any).fathom.trackGoal('LPYARGQJ', 0);
    } catch (error) {
      console.log(error);
    }
    window.location.href = 'https://app.flujo.io/get-started';
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  // Form Submit
  onSubmit() {
    this.getstarted();
    this.submitted = true;
    this.existingMail = false;
    if (this.registerForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.httpSerive.httpPost(this.httpSerive.postURL, this.registerForm.value).subscribe(
      res => {
        if (!res.error) {
          console.log(this.registerForm.value);
          this.submitted = false;
          this.registerForm.reset();
          this.isLoading = false;
          console.log('Form Successfully Submited...');
          this.httpSerive.openDialog();
        } else {
          if (res.existing) {
            this.existingMail = true;
            setTimeout(() => {
              this.existingMail = false;
            }, 5000);
          }
          this.isLoading = false;
          console.log('Form not Submited');
        }
      }
    );
  }
}
