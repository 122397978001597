import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  clickedId = 0;
  constructor(private router: Router) { }
  productItem(id) {
    this.clickedId = id;
    this.router.navigate(['/products']);
  }
  resProductItem(id) {
    this.clickedId = id;
    this.router.navigate(['/responsive-product']);
  }
}
