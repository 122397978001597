import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// import { MatSnackBarModule } from '@angular/material';
// import { MatSelectModule } from '@angular/material/select';
import {  MatSnackBarModule, MatSelectModule, MatDialogModule, MatExpansionModule, MatTabsModule } from '@angular/material';


import { HomeComponent } from './home/home.component';

import { ProductService } from './services/product.service';
import { HttpService } from './http.service';
import { HeroPageComponent } from './new-ui/hero-page/hero-page.component';
import { GetStartedComponent } from './new-ui/get-started/get-started.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './new-ui/features/features.component';
import { SecurityComponent } from './new-ui/security/security.component';
import { AppDirectoryComponent } from './new-ui/app-directory/app-directory.component';
import { ContactUsComponent } from './new-ui/contact-us/contact-us.component';
import { HelpComponent } from './help/help.component';
import { HtmlSafePipe } from './html-safe.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { GoodKarmaComponent } from './new-ui/good-karma/good-karma.component';
import { SubmitFormComponent } from './submit-form/submit-form.component';
import { IntersectionObserverDirective } from './_directives/intersection-observer.directive';
import { VideoViewportDirective } from './_directives/video-viewport.directive';
import { PlainTextPipe } from './_pipes/plain-text.pipe';
import { AboutUsComponent } from './new-ui/about-us/about-us.component';
import { ErrorPageComponent } from './new-ui/error-page/error-page.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HeroPageComponent,
    GetStartedComponent,
    PricingComponent,
    FeaturesComponent,
    SecurityComponent,
    AppDirectoryComponent,
    ContactUsComponent,
    HelpComponent,
    HtmlSafePipe,
    EllipsisPipe,
    GoodKarmaComponent,
    SubmitFormComponent,
    IntersectionObserverDirective,
    VideoViewportDirective,
    PlainTextPipe,
    AboutUsComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSnackBarModule,
    HttpClientModule,
    MatSelectModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    HttpClientJsonpModule
  ],
  providers: [ProductService, HttpService],
  bootstrap: [AppComponent],
  entryComponents: [SubmitFormComponent]
})
export class AppModule { }
