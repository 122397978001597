import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import { BlogService } from 'src/app/blog.service';
import { DataCommunicationService } from 'src/app/_services/data-communication.service';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-hero-page',
  templateUrl: './hero-page.component.html',
  styleUrls: ['./hero-page.component.scss'],
  animations: [
    trigger("pageAnimations", [
      transition(":enter", [
        query("div", [
          style({ opacity: 0, transform: "translateY(-100px)" }),
          stagger(-30, [
            animate(
              "500ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class HeroPageComponent implements OnInit {
  items = [{}, {}, {}];
  closeBanner = true;
  constructor(private blogService: BlogService, public dcService$: DataCommunicationService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    // this.getPosts();
    // this.setMetaTags();
    this.seoService.updateTags('');
  }

  getPosts() {
    this.blogService.getBlogPosts().subscribe((res: any[]) => {
      this.items = res.splice(0, 3);
    });
  }

  gotoFeature(id) {
    this.dcService$.$activeFeature.next(id);
  }


  // setMetaTags() {
  //   this.seoService.updateTitle('Team Communication and Collaboration App | Flujo');
  //   this.seoService.updateDescription
  //     ('Flujo is a business communication app built with key business essentials like messaging, kanbans, drive, meetings, tasks, notes & more to create a happy & productive workspace.');
  //   this.seoService.updateOgUrl('https://www.flujo.io/');
  // }

}
