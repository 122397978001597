import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { WindowSizeService } from '../_services/window-size.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  productLinks = [
    { name: 'Features', link: '/features' },
    { name: 'Security', link: '/security' },
    { name: 'Pricing', link: '/pricing' },
    // { name: 'Case studies', label: '(Coming soon)' }
  ];

  resourcesLinks = [
    { name: 'Help', href: 'https://intercom.help/flujoapp/en' },
    { name: 'Blog', href: 'https://blog.flujo.io/' },
    { name: 'App Directory', link: '/appdirectory' },
    { name: 'Download', href: 'https://help.flujo.io/hc/en-us/articles/900001494963-Downloading-flujo' },
    // { name: 'Good karma!', link: '/goodkarma' },
  ];

  companyLinks = [
    // { name: 'About us', link: '/aboutus' },
    // { name: 'Careers', href: 'https://angel.co/company/flujoapp' },
    { name: 'Careers', href: 'https://www.instahyre.com/jobs-at-flujo-2/' },
    { name: 'Contact', link: '/contactus' },
    { name: 'Terms', href: '/legal', self: true },
    { name: 'Privacy', href: '/privacy', self: true },
    // { name: 'Terms', href: 'https://help.flujo.io/hc/en-us/articles/900001498846-Flujo-Terms-of-Service'},
    // { name: 'Privacy', href: 'https://help.flujo.io/hc/en-us/articles/900001496783-Flujo-Privacy-Policy' },
  ]
  isTabWidth: boolean;
  tabViewObserver: Subscription;
  remoteAreas = [
    'Hyderabad, India', 'Bengaluru, India', 'Detroit, USA', 'Milan, Italy'
  ];

  activeArea = this.remoteAreas[0];

  constructor(
    private windowService: WindowSizeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.tabViewObserver = this.windowService.$tabView.subscribe(res => {
      this.isTabWidth = res;
    });
    this.changingAreas();
  }
  ngOnDestroy() {
    if (this.tabViewObserver) {
      this.tabViewObserver.unsubscribe();
    }
  }

  changingAreas() {
    let count = 0;
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        if (this.remoteAreas.length - 1 > count) {
          count++;
        } else {
          count = 0;
        }
        this.activeArea = this.remoteAreas[count];
      }, 3000)
    }
  }

}
