import { Component, OnInit, HostListener } from '@angular/core';
import { WindowSizeService } from './_services/window-size.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'flujo';
  isMobileView: boolean;
  isTabView: boolean;
  isHambergerMenu: boolean;
  constructor(private windowService: WindowSizeService) { }
  @HostListener('window:resize', ['$event.target'])
  onClick() {
    this.windowSizeCheck();
  }
  ngOnInit() {
    this.windowSizeCheck();
  }
  windowSizeCheck() {
    // Below 820px
    if (window.innerWidth <= 820) {
      if (!this.isTabView) {
        this.windowService.$tabView.next(true);
      }
      this.isTabView = true;
    } else {
      if (this.isTabView) {
        this.windowService.$tabView.next(false);
      }
      this.isTabView = false;
    }
    // Below 600px
    if (window.innerWidth <= 600) {
      if (!this.isMobileView) {
        this.windowService.$mobileView.next(true);
      }
      this.isMobileView = true;
    } else {
      if (this.isMobileView) {
        this.windowService.$mobileView.next(false);
      }
      this.isMobileView = false;
    }
    // HambergerMenu
    if (window.innerWidth <= 680) {
      if (!this.isHambergerMenu) {
        this.windowService.$hambergerMenu.next(true);
      }
      this.isHambergerMenu = true;
    } else {
      if (this.isHambergerMenu) {
        this.windowService.$hambergerMenu.next(false);
      }
      this.isHambergerMenu = false;
    }
  }
}
