import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataCommunicationService {
  public $activeKarma = new BehaviorSubject<number>(null);
  public $activeFeature = new BehaviorSubject<number>(null);
  getStartedURL = 'https://app.flujo.io/get-started';
  constructor() { }
  public getstarted() {
    try {
      (window as any).fathom.trackGoal('LPYARGQJ', 0);
    } catch (error) {
      console.log(error);
    }
    // window.location.href = 'https://app.flujo.io/get-started';
    window.open(this.getStartedURL, '_blank');
  }
}
