<div class="body_conatiner">
    <div class="block--header">
        <h1 data-value="Security">Security</h1>
    </div>
    <p class="sub--head">
        As a platform that is entrusted with preserving an organization’s knowledge, communication and data—we are
        committed to protecting the integrity of our users’ data from unauthorized access.
    </p>
    <div class="content-b">
        <div class="icon-b">
            <img src="../../../assets/illustrations/security.svg" alt="Security">
        </div>
        <div class="white--card">
            <ul>
                <li *ngFor="let item of items">
                    <p>{{item.name}}</p>
                    <span class="check-cirle"></span>
                </li>
            </ul>
        </div>
    </div>
    <div class="contact-b">
        <div>
            <h6>Contact</h6>
            <h2>
                Something bothering you about our Security?
            </h2>
            <p>
                If there is something which is bothering you or if you think you might have found something vulnerable
                within Flujo, please get in touch with our security team.
            </p>
            <p>
                Read more about security practices : <a
                    href="https://help.flujo.io/hc/en-us/articles/900001496783-Flujo-Privacy-Policy">privacy policy,
                </a>
                <a href="https://help.flujo.io/hc/en-us/articles/900001498846-Flujo-Terms-of-Service">terms</a> and
                <a href="https://help.flujo.io/hc/en-us/articles/900001499646-Flujo-Security-Policy">security
                    policy.</a>
                <!-- <a href="https://help.flujo.io/hc/en-us/articles/900001499646-Flujo-Security-Policy">GDPR policy.</a> -->
            </p>
            <div class="btn--b">
                <button routerLink="/contactus" class="button-grape">Contact us</button>
            </div>
        </div>
    </div>
</div>