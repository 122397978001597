import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { DataCommunicationService } from '../_services/data-communication.service';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  freePlans = [
    '10 users', '10 guests', '10 Gb drive space', 'One-to-one AV'
  ];
  proPlans = [
    'Unlimited messaging', '100GB drive space/user', 'SAML & 2fa', 'One-to-one & Group AV'
  ];
  enterprisePlans = [
    'Multiple workspaces', 'Enterprise grade security & Compliances', 'Dedicated account manager'
  ];
  communicationItems = [
    {
      name: 'One-to-one messaging',
      free: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    {
      name: 'Message history',
      free: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    {
      name: 'Search',
      free: 'Yes',
      pro: 'Yes',
      enterprise: 'Yes',
    },
    {
      name: 'Teams (Public & Private)',
      free: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    {
      name: 'Single Team Guests',
      free: '1:1 with users',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    {
      name: 'Multi Team Guests',
      free: 'No',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
      isSubText: true
    },
    {
      name: 'One-to-one Audio/Video Calling',
      free: 'Unlimited',
      pro: 'Unlimited',
      enterprise: 'Unlimited',
    },
    {
      name: 'Group Video Conferencing(With Jitsi)',
      free: 'No',
      pro: 'Yes (Upto 50 users)',
      enterprise: 'Yes',
    },
    {
      name: 'Alerts & Reminders',
      free: 'Yes',
      pro: 'Yes',
      enterprise: 'Yes',
    }
  ];
  collaborationPlans = [
    { name: 'Drive', free: '10 GB/user', pro: '100 GB/user', enterprise: '1 TB/user', },
    { name: 'Storage for Guests', free: '1 GB/user', pro: '5 GB/user', enterprise: '10 GB/user', },
    { name: 'Public sharing of Drive files with ACL', free: 'No', pro: 'Coming soon', enterprise: 'Coming soon', },
    // { name: 'Tasks', free: 'Unlimited', pro: 'Unlimited', enterprise: 'Unlimited', },
    { name: 'Meetings', free: 'Unlimited', pro: 'Unlimited', enterprise: 'Unlimited', },
  ];
  workspacePlans = [
    { name: 'Workspaces', free: 'Single', pro: 'Currently single', enterprise: 'Currently single', isSubText: true},
    { name: 'Integration with third-party apps', free: '10', pro: 'Unlimited', enterprise: 'Unlimited', },
    { name: 'Workspace Administration', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
  ];
  productivityPlans = [
    { name: 'Kanbans', free: '10 boards/user', pro: 'Unlimited', enterprise: 'Unlimited', },
    { name: 'Notes', free: 'Unlimited', pro: 'Unlimited', enterprise: 'Unlimited', },
    { name: 'Bot', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
  ];
  securityPlans = [
    { name: 'SAML based single sign-on', free: 'No', pro: 'Coming soon', enterprise: 'Coming soon', },
    { name: '2fa', free: 'No', pro: 'Coming soon', enterprise: 'Coming soon', },
    { name: 'Data security', free: 'In transit and at rest', pro: 'In transit and at rest', enterprise: 'In transit and at rest', },
    { name: 'Export of messages', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
    { name: 'Data Residency', free: 'Coming soon', pro: 'Coming soon', enterprise: 'Coming soon', },
  ];
  supprortPlans = [
    { name: '99.8% Guaranteed SLA', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
    { name: 'Standard Support', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
    { name: 'Self-Service Knowledge Base', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
    { name: 'Email support', free: 'Yes', pro: 'Yes', enterprise: 'Yes', },
    { name: 'Dedicated Account Manager', free: 'No', pro: 'No', enterprise: 'Yes', },
  ];
  panels = [
    {
      id: 1,
      title: "Will I be charged when my 90-day free trial is over?",
      description: "No, you will not be charged until you choose to upgrade to our Pro plan. If you choose not to upgrade your account to one of the paid plans, you will be automatically moved to the free plan after your trial expires. If your usage during the trial has exceeded the limits of the free plan, then all the additional data across the features will automatically switch to a read-only state.",
      isOpen: false
    },
    {
      id: 2,
      title: "Can I cancel at any time?",
      description: "Yes, you can cancel your subscription at any point of time and be free to continue with our free plan. If you are a <b>non-profit, woman entrepreneur</b> or an <b>early stage startup</b>—we have some exciting discounts in store for you. <a target=\"_blanck\" href=\"https://flujo.typeform.com/to/UnzjncVZ\">Fill this form out</a> to check your eligibility.",
      isOpen: false
    },
    {
      id: 3,
      title: "What are the payment methods available?",
      description: "You can use a credit card to pay for your monthly or annual subscriptions. Write to us at <a target=\"_blanck\" href=\"mailto:support@flujo.io\">support@flujo.io</a> for any payment related questions.",
      isOpen: false
    },
    {
      id: 4,
      title: "Do you bill in local currencies?",
      description: "All the transactions on our payment gateway will happen in USD. Depending on where you live, your card issuer or bank may add a currency conversion or foreign transaction fee to your payment. Please contact your bank for information on what rates and fees may apply.",
      isOpen: false
    },
    {
      id: 5,
      title: "How about taxes?",
      description: "The pricing that you see on our website is inclusive of all the taxes. Your card issuer or bank may add certain additional charges depending on where you live. Please contact your bank for information on such charges.",
      isOpen: false
    },
    {
      id: 6,
      title: "I have more questions?",
      description: "We’d love to hear you out, simply hit the messaging bubble you see on the bottom right corner to instantly get in touch with us or can also leave us a message <a target=\"_blanck\" href=\"mailto:support@flujo.io\">here.</a>",
      isOpen: false
    }
  ];
  tableBlock1 = false;
  tableBlock2 = true;
  tableBlock3 = true;
  tableBlock4 = true;
  tableBlock5 = true;
  tableBlock6 = true;
  proPrice = 3;
  activeTab = 1;
  constructor(public dcService$: DataCommunicationService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
    }
    this.priceChanged(false);
    this.seoService.updateTags('pricing');
  }

  toggleMenu(id) {
    if (this.activeTab === id) {
      this.activeTab = null;
    } else {
      this.activeTab = id;
    }
    // if (id === 1) {
    //   this.tableBlock1 = !this.tableBlock1;
    // } else if (id === 2) {
    //   this.tableBlock2 = !this.tableBlock2;
    // } else if (id === 3) {
    //   this.tableBlock3 = !this.tableBlock3;
    // } else if (id === 4) {
    //   this.tableBlock4 = !this.tableBlock4;
    // } else if (id === 5) {
    //   this.tableBlock5 = !this.tableBlock5;
    // } else if (id === 6) {
    //   this.tableBlock6 = !this.tableBlock6;
    // }
  }

  priceChanged(value) {
    if (value) {
      this.proPrice = 6;
    } else {
      this.proPrice = 3;
    }
  }

  togglePanelsMenu(item) {
    this.panels.forEach(el => {
      if (item.id === el.id) {
        item.isOpen = !item.isOpen;
      } else {
        el.isOpen = false
      }
    });
  }
  goKarma(val) {
    this.dcService$.$activeKarma.next(val);
  }



}
