import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IResponseModel } from '../models/IHttpResponse.model';
import { SnackBarService } from '../services/snackbar.service';
import { Router, NavigationEnd } from '@angular/router';
import { SubmitFormComponent } from '../submit-form/submit-form.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../http.service';
import { WindowSizeService } from '../_services/window-size.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  email: any;
  horizontal = [
    {
      name: 'Messenger',
      about: 'Create threads on any message. Do a Flash chat that self erases and much more.'
    },
    {
      name: 'Drive',
      about: 'Get a whopping 100 GB space per user. Lend and borrow space when required with your teammates.'
    },
    {
      name: 'Calendar',
      about: 'Be it Events, Meetings, Reminders or Tasks, experience the power of being in total control with our Calendar.'
    },
  ];
  vertical = [
    {
      name: 'Campaign Manager',
      about: 'Schedule, execute and analyse your marketing campaigns more effectively and track their success.'
    },
    {
      name: 'Social Media',
      about: 'Post and manage campaigns across Facebook, Twitter, Instagram and LinkedIn simultaneously'
    },
    {
      name: 'Email',
      about: 'Drive more sales by running dedicated Email campaigns'
    },
    {
      name: 'SMS',
      about: 'Just import contacts and run SMS campaigns on the go!'
    },
  ];
  moreApps = [
    {
      name: 'Notes',
      about: 'Note all your Ideas'
    },
    {
      name: 'Polls',
      about: 'Conduct quick polls'
    },
    {
      name: 'Teams',
      about: 'Create customised teams'
    },
    {
      name: 'Bots',
      about: 'Specially designed 6 Bots'
    },
    {
      name: 'App Directory',
      about: 'More power with more Apps.'
    },
  ];
  resources = [
    {
      name: 'Help',
      about: 'Just in case you need us, we are just a click away.'
    },
    {
      name: 'Videos',
      about: 'Tips and Tricks to learn more on just about everything'
    },
    {
      name: 'Demo',
      about: 'Step by step guide to make things simple for you at every stage'
    },
  ];
  solutions = [
    'Engineering',
    'IT',
    'Sales',
    'Marketing',
    'Project Management',
    'Advertising Agencies',
    'Non Profits',
    'Human Resources'
  ];

  scrollingStart: boolean;
  isValidEmail: boolean;
  isExtraLink = false;
  isHome: boolean;
  isgetearlyaccess: boolean;
  name: any;
  isSideMenu: any;
  registerForm: FormGroup;
  submitted = false;
  existingMail: boolean;
  isLoading: boolean;
  isPartnership: boolean;
  isHambergerMenu: boolean;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const windowPos = window.pageYOffset;
    if (windowPos > 0) {
      this.scrollingStart = true;
    } else {
      this.scrollingStart = false;
    }
  }
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpSerive: HttpService,
    private sizeService: WindowSizeService
  ) { }


  ngOnInit() {
    this.sizeObserver();
    if (this.router.url === '/appdirectory') {
      this.isExtraLink = true;
    }
    if (this.router.url === '/') {
      this.isHome = false;
    } else {
      this.isHome = true;
    }
    if (this.router.url === '/partnerships') {
      this.isPartnership = true;
    } else {
      this.isPartnership = false;
    }

    this.router.events.subscribe((val) => {
      this.isSideMenu = false;
      if (val instanceof NavigationEnd) {
        this.isSideMenu = false;
        if (val.url === '/appdirectory') {
          this.isExtraLink = true;
          this.isHome = true;
        } else {
          this.isExtraLink = false;
          if (val.url === '/') {
            this.isHome = false;
          } else {
            this.isHome = true;
          }
        }
        if (val.url === '/partnerships') {
          this.isPartnership = true;
        } else {
          this.isPartnership = false;
        }
      }
    });

    // Registration Form
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  // Form Submit
  onSubmit() {
    this.submitted = true;
    this.existingMail = false;
    if (this.registerForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.httpSerive.httpPost(this.httpSerive.postURL, this.registerForm.value).subscribe(
      res => {
        if (!res.error) {
          this.isgetearlyaccess = false;
          console.log(this.registerForm.value);
          this.submitted = false;
          this.registerForm.reset();
          console.log('Form Successfully Submited...');
          this.httpSerive.openDialog();
          this.isLoading = false;
        } else {
          if (res.existing) {
            this.existingMail = true;
            setTimeout(() => {
              this.existingMail = false;
            }, 5000);
            this.isLoading = false;
          }
          this.isLoading = false;
          console.log('Form not Submited');
        }
      }
    );
  }

  navigateLogin() {
    window.location.href = 'https://app.flujo.io/signin';
    // window.location.href = 'https://development.flujo.io/workspace/';
    // window.location.href = 'https://alpha.flujo.io/login/';
  }

  getstarted() {
    try {
      (window as any).fathom.trackGoal('LPYARGQJ', 0);
    } catch (error) {
      console.log(error);
    }
    window.location.href = 'https://app.flujo.io/get-started';
  }

  helpRedirect() {
    window.location.href = 'https://support.flujo.io/hc/en-us';
    this.closeMenu();
  }

  getearlyaccess = (e) => {
    e.stopPropagation();
    this.isgetearlyaccess = !this.isgetearlyaccess;
  }
  closeMenuForm() {
    this.isgetearlyaccess = false;
  }
  openMenu() {
    this.isSideMenu = !this.isSideMenu;
  }
  closeMenu() {
    this.isSideMenu = false;
  }

  sizeObserver() {
    this.sizeService.$hambergerMenu.subscribe(res => {
      this.isHambergerMenu = res;
    });
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  downloadApp() {
    if (this.getMobileOperatingSystem() === 'iOS') {
      window.open('https://apps.apple.com/in/app/flujo/id1496570932', '_blanck')
    } else {
      window.open('https://play.google.com/store/apps/details?id=io.flujo.app', '_blanck')
    }
  }

}
