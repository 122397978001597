<div class="pricing-container body_conatiner">
	<div id="scheduleStrip" class="schedule--strip">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.056" height="19.576" viewBox="0 0 15.056 19.576">
                <g transform="translate(-580.096 -208)">
                    <path d="M14.812 29.271c1.464 0 2.651-.905 2.651-2.023h-5.3c-.003 1.118 1.183 2.023 2.649 2.023z"
                        style="fill:#ec316c" transform="translate(572.813 198.305)" />
                    <path
                        d="M19.845 16.746l-1.163-1.514V8.821c0-2.512-1.679-3.646-3.409-4.138a2.787 2.787 0 0 0-2.65-2.86 2.785 2.785 0 0 0-2.648 2.86c-1.731.492-3.41 1.627-3.41 4.138v6.41L5.4 16.746c-.6.786-.287 1.429.7 1.429h13.041c.992 0 1.308-.644.704-1.429zM11.51 4.391a1.224 1.224 0 0 1 1.114-1.067 1.224 1.224 0 0 1 1.115 1.067 10.249 10.249 0 0 0-2.229 0z"
                        style="fill:#ec316c" transform="translate(575 206.177)" />
                </g>
            </svg>
            <p>
                Learn how Flujo can make your teams more productive.
                <!-- We are free till December 2020 due to COVID-19 and Early Signups -->
            </p>
        </div>
        <a href="https://calendly.com/flujoapp/demo" target="_new">Schedule a Demo Now
            <svg style="fill: #ec316c" xmlns="http://www.w3.org/2000/svg" width="10.274" height="7.048"
                viewBox="0 0 10.274 7.048">
                <g>
                    <path d="M6.805 7.048l3.469-3.472L6.7 0l-.707.707L8.86 3.576 6.097 6.341l.708.707z" />
                    <path d="M8.86 3.076H0v1h8.86" />
                </g>
            </svg>
        </a>
    </div>
	<div class="block--header">
		<h1 data-value="Pricing">Pricing</h1>
	</div>
	<!-- <p class="sub--head">
		Hello! We are Flujo. The newest and the smartest way to not just manage work but take it to the next level of
		productivity. By adding and breaking silos
	</p> -->
	<p class="sub--head">
		Who says freedom comes at a price? Unleash the productivity of your teams with a no strings attached 90-day
		trial.
	</p>
	<div class="price-block">
		<h4>
			Annually
			<label class="toogle-bar">
				<input #priceToggle (change)="priceChanged(priceToggle.checked)" type="checkbox">
				<span></span>
			</label>
			Monthly
		</h4>
		<div class="pricing--cards">
			<div class="white-card">
				<h2>Free</h2>
				<h1><span class="sub-text">$</span>0<span class="--text">/ month</span></h1>
				<h6>&nbsp;</h6>
				<ul class="checkbox--list">
					<li *ngFor="let item of freePlans">
						<span class="check-cirle"></span>
						<p>{{item}}</p>
					</li>
				</ul>
				<div class="btn-b">
					<button class="button-grape" (click)="dcService$.getstarted()">Try for free</button>
				</div>
			</div>
			<div class="white-card pro--card">
				<div class="element--1">
					<svg xmlns="http://www.w3.org/2000/svg" width="148.786" height="184.581"
						viewBox="0 0 148.786 184.581">
						<g transform="translate(-14.669)" style="opacity:.1">
							<g transform="translate(14.669)">
								<path
									d="M236.487 244.676v-134.57a7.32 7.32 0 0 1 7.32-7.32h134.132a7.32 7.32 0 0 1 5.184 12.487L248.991 249.844a7.32 7.32 0 0 1-12.504-5.168z"
									style="fill:#ec316c" transform="translate(-236.487 -102.786)" />
							</g>
							<g transform="translate(15 169.912)">
								<path
									d="M219.871 332.587a7.321 7.321 0 0 1 0-10.352 7.32 7.32 0 0 1 12.5 5.164 7.32 7.32 0 0 1-12.5 5.188z"
									style="fill:#ec316c" transform="translate(-217.728 -320.077)" />
							</g>
						</g>
					</svg>
				</div>
				<h2>Pro</h2>
				<h1 class="clr-red"><span class="sub-text">$</span>{{proPrice}}<span class="--text">/ month</span></h1>
				<h6>( 30% Discount on annual contracts )</h6>
				<ul class="checkbox--list">
					<li *ngFor="let item of proPlans">
						<span class="check-cirle"></span>
						<p>{{item}}</p>
					</li>
				</ul>
				<div class="btn-b">
					<button class="button-grape" (click)="dcService$.getstarted()">Try for free</button>
				</div>
			</div>
			<div class="white-card">
				<h2>Enterprise</h2>
				<h1 class="sm--hide"><span class="--text"></span>&nbsp;</h1>
				<h6>&nbsp;</h6>
				<ul class="checkbox--list">
					<li *ngFor="let item of enterprisePlans">
						<span class="check-cirle"></span>
						<p>{{item}}</p>
					</li>
				</ul>
				<div class="btn-b">
					<button routerLink="/contactus" class="button-grape">Contact us</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Violet Box -->
	<div class="violet--block">
		<div class="img-box">
			<img src="../../assets/images/gift-box.svg" alt="Flujo for changemakers.">
		</div>
		<div class="content-block">
			<h3>
				Flujo for changemakers.
			</h3>
			<p>
				In our endeavour of creating connected and productive workplaces, we would love to join hands with
				changemakers across the globe in their journeys.
				<br>
				If you are a non-profit, woman entrepreneur or an early stage startup—we have some exciting offers in
				store for you.
			</p>
			<ul>
				<li>
					<!-- <a routerLink="/goodkarma" (click)="goKarma(1)"> -->
					<a href="https://flujo.typeform.com/to/UnzjncVZ" target="_blank">
						Women entrepreneurs
					</a>
				</li>
				<li>
					<!-- <a routerLink="/goodkarma" (click)="goKarma(2)"> -->
					<a href="https://flujo.typeform.com/to/UnzjncVZ" target="_blank">
						Non-profit organisations
					</a>
				</li>
				<li>
					<!-- <a routerLink="/goodkarma" (click)="goKarma(3)"> -->
					<a href="https://flujo.typeform.com/to/UnzjncVZ" target="_blank">
						Early-stage startups
					</a>
				</li>
			</ul>
		</div>
	</div>

	<!-- Table View -->
	<div class="tables--container">
		<!-- Communication -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr class="btns--b">
						<th class="title-td">
							<h3>
								Communication
							</h3>
						</th>
						<th>
							<p>Free</p>
							<button (click)="dcService$.getstarted()" class="t-btn">Try for free</button>
						</th>
						<th>
							<p>Pro</p>
							<button (click)="dcService$.getstarted()" class="t-btn">Try for free</button>
						</th>
						<th>
							<p>Enterprise</p>
							<button routerLink="/contactus" class="t-btn">Contact us</button>
							<button [ngClass]="{'rorate180deg': activeTab === 1}" (click)="toggleMenu(1)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 1">
					<tr *ngFor="let item of communicationItems">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
							<h6 *ngIf="item?.isSubText">(billed as additional users)</h6>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
							<h6 *ngIf="item?.isSubText">(billed as additional users)</h6>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Collaboration -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr>
						<th class="title-td">
							<h3>
								Collaboration
							</h3>
						</th>
						<th>
							<p>Free</p>
						</th>
						<th>
							<p>Pro</p>
						</th>
						<th>
							<p>Enterprise</p>
							<button [ngClass]="{'rorate180deg': activeTab === 2}" (click)="toggleMenu(2)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 2">
					<tr *ngFor="let item of collaborationPlans">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Workspace -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr>
						<th class="title-td">
							<h3>
								Workspace
							</h3>
						</th>
						<th>
							<p>Free</p>
						</th>
						<th>
							<p>Pro</p>
						</th>
						<th>
							<p>Enterprise</p>
							<button [ngClass]="{'rorate180deg': activeTab === 3}" (click)="toggleMenu(3)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 3">
					<tr *ngFor="let item of workspacePlans">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
							<h6 *ngIf="item?.isSubText">(multiple workspaces coming soon)</h6>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
							<h6 *ngIf="item?.isSubText">(multiple workspaces coming soon)</h6>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Productivity -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr>
						<th class="title-td">
							<h3>
								Productivity
							</h3>
						</th>
						<th>
							<p>Free</p>
						</th>
						<th>
							<p>Pro</p>
						</th>
						<th>
							<p>Enterprise</p>
							<button [ngClass]="{'rorate180deg': activeTab === 4}" (click)="toggleMenu(4)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 4">
					<tr *ngFor="let item of productivityPlans">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Security -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr>
						<th class="title-td">
							<h3>
								Security
							</h3>
						</th>
						<th>
							<p>Free</p>
						</th>
						<th>
							<p>Pro</p>
						</th>
						<th>
							<p>Enterprise</p>
							<button [ngClass]="{'rorate180deg': activeTab === 5}" (click)="toggleMenu(5)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 5">
					<tr *ngFor="let item of securityPlans">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Support -->
		<div class="table--menu">
			<table width="100%">
				<thead>
					<tr>
						<th class="title-td">
							<h3>Support</h3>
						</th>
						<th>
							<p>Free</p>
						</th>
						<th>
							<p>Pro</p>
						</th>
						<th>
							<p>Enterprise</p>
							<button [ngClass]="{'rorate180deg': activeTab === 6}" (click)="toggleMenu(6)"
								class="arrow-btn"></button>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="activeTab === 6">
					<tr *ngFor="let item of supprortPlans">
						<td class="title-td">
							<p>{{item.name}}</p>
						</td>
						<td>
							<p>{{item.free}}</p>
						</td>
						<td>
							<p>{{item.pro}}</p>
						</td>
						<td>
							<p>{{item.enterprise}}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>

	<div class="expansion--menu--block">
		<h2>Frequently asked questions</h2>
		<ul>
			<li *ngFor="let panel of panels" [ngClass]="{'open': panel.isOpen}">
				<div class="menu-header" (click)="togglePanelsMenu(panel)">
					<h3>{{panel.title}}</h3>
					<span class="icon--plus"></span>
				</div>
				<div *ngIf="panel.isOpen" class="content-b">
					<p [innerHtml]="panel.description | htmlSafe">{{panel.description}}</p>
				</div>
			</li>
		</ul>
	</div>
</div>