import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatExpansionModule } from '@angular/material';
import { SeoService } from '../seo.service';
import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [
    trigger("flyInOut", [
      state(
        "in",
        style({
          height: 0,
          transform: "translateY(0)",
          opacity: 0
        })
      ),
      transition("void => *", [
        style({ height: 'auto', transform: "translateY(50px)", opacity: 0 }),
        group([
          animate(
            "0.3s 0.1s ease",
            style({
              transform: "translateY(0)",
              height: 'auto'
            })
          ),
          animate(
            "0.3s ease",
            style({
              opacity: 1
            })
          )
        ])
      ]),
      transition("* => void", [
        group([
          animate(
            "0.1s ease",
            style({
              transform: "translateY(0)",
              height: 'auto'
            })
          ),
          animate(
            "0.1s 0.1s ease",
            style({
              transform: "translateY(0)",
              opacity: 0
            })
          )
        ])
      ])
    ])
  ]
})
export class HelpComponent implements OnInit {
  helpCenterItems = [
    {
      img: 'help_getstart.svg',
      title: 'Getting Started',
      subText: 'Say hello to FLUJO. Get to know us better.',
      link: 'https://support.flujo.io/getting-started/getting-started',
      alt: 'Welcome on board'
    },
    {
      img: 'help_workspace.svg',
      title: 'Workspace',
      subText: 'Know the first steps about joining us, bring your colleagues on board and get to know about our features.',
      link: 'https://support.flujo.io/workspace/creating-workspace',
      alt: 'Colleagues invite'
    },
    {
      img: 'help_apps.svg',
      title: 'Apps',
      subText: 'Know more about Messenger, Drive, Campaign Manager and Calendar in detail.',
      link: 'https://support.flujo.io/apps/apps',
      alt: 'Apps - Messenger, Drive, Campaign Manager and Calendar'
    },
    {
      img: 'help_settings.svg',
      title: 'Settings',
      subText: 'Know every setting to make your life easier and simpler.',
      link: 'https://support.flujo.io/top-navigation-bar/settings',
      alt: 'Settings'
    },
    {
      img: 'Integration_website.svg',
      title: 'Integration',
      subText: 'Get to know all third-party apps, pre-installed apps and much more.',
      // link: 'https://support.flujo.io/app-directory/app-directory',
      link: 'https://support.flujo.io',
      alt: 'Thrid party app directory'
    },
    {
      img: 'help_price.svg',
      title: 'Pricing / Upgrade',
      subText: 'We haven’t yet figured out our pricing, so everything is free. We’ll keep you posted if we change our mind!',
      // link: 'https://support.flujo.io/pricing-and-upgrades/upgrade-workspace',
      link: 'https://support.flujo.io',
      alt: 'Pricing'
    },
  ];
  faqs = [
    { text: 'How do I change my password?' },
    { text: 'How do I add a guest user to workspace?' },
    { text: 'How to create a private team ?' },
    { text: 'How do I integrate google drive account with FLUJO Drive?' },
    { text: 'How do I invite people to workspace?' },
    { text: 'How do I add an integration app to FLUJO workspace?' },
    { text: 'How do I create a meeting?' },
    { text: 'How do I check/change user permissions in a workspace?' },
    { text: 'How do I refer someone to Flujo?' },
    { text: 'How do I manage Teams?' },

  ];


  panels = [
    {
      title: 'What is FLUJO?',
      content: 'Its a Cloud based Web app used to manage official workspace for your organisation . Its a one stop solution for all your office communication and collaboration . Main Features of the Web app include communication through Messenger, storage space through Drive, Event Management, Meetings management, Tasks management etc.',
      isOpen: false
    },
    {
      title: 'How do I create a new workspace for my organisation on FLUJO app or Website?',
      content: 'After going to https://app.flujo.io, click on register link and follow the instructions. Whoever creates the workspace becomes the owner of the workspace by default. Workspaces can only be created using the official email IDs of the organisations. Members joining the workspace should have official email IDs and all the members of the workspace should have same email Domain. Only one workspace can be created for one organisation, however multiple teams specific to their office needs can be created as per the need. (or) Simply go to the official link',
      anchorTag: 'click here',
      link: 'https://support.flujo.io/workspace/creating-workspace',
      isOpen: false
    },
    {
      title: 'How do I join my organisations existing workspace?',
      content: 'You can join your organisations existing workspace in following 2 ways. 1) By clicking on Register link on the home screen of https://app.flujo.io and filling the required info. 2) By following the link received through invitation sent by members /admin /owner belonging to your organisations workspace. for more info click',
      anchorTag: 'click here',
      link: 'https://support.flujo.io/workspace/registering-to-workspace',
      isOpen: false
    },
    {
      title: 'Where do I find the name of my workspace?',
      content: 'It is present on the top left corner of your screen on side navigation bar. It is above the name of the user logged in.',
      isOpen: false
    },
    {
      title: 'What are various important preferences that need to be set once the workspace is created?',
      content: 'Please set the access levels for various roles inside the workspace using the administration section . Navigate to administration section by clicking on the settings icon on the top right hand side.',
      isOpen: false
    },
    {
      title: 'Can a Workspace have multiple owners?',
      content: 'Yes the workspace can have multiple owners. Only restriction is that a workspace should have atleast one owner. Hence if a owner of the workspace dont want to be the owner anymore ,he has to first create a different owner in order to drop his own privileges to member or admin.',
      isOpen: false
    },
    {
      title: 'Can a admin change the privileges of owner?',
      content: 'No admin cannot the privilege level of owner , but owner can change the privilege level of admin.',
      isOpen: false
    },
    {
      title: 'How to delete my organisations workspace?',
      content: 'Currently there is no way to delete a workspace once created. We are working to implement this feature.',
      isOpen: false
    },
    {
      title: 'What are different roles your workspace Members can have?',
      content: 'Members of any workspace can only have 3 roles 1) Owner - Person who created the workspace 2) Admin - Person chosen to be the admin of the workspace. 3) Member - Normal user of the workspace.',
      isOpen: false
    },
    {
      title: 'How do I change my password?',
      content: 'Navigate to My Profile section by clicking on the Settings icon in the top bar. Enter your new password in the change password section and click on Change Password button.',
      isOpen: false
    },
    {
      title: 'How to create a private team?',
      content: 'In the side navigation bar of the application, click on the "+" icon to create a new Team. After clicking on it, you will be asked to choose the team type. Choose Private and enter the details of the team members and submit.',
      isOpen: false
    },
    {
      title: 'How do I Invite people to my Organisations Workspace?',
      content: 'Invitations can be sent in multiple ways. Please refer to our official support link',
      anchorTag: 'click here',
      link: 'https://support.flujo.io/top-navigation-bar/invite',
      isOpen: false
    },
    {
      title: 'How do I create a meeting?',
      content: 'Navigate to your Account Dashboard and click on the Create button. Select Meetings and enter the required details. Click on Continue to schedule a meeting and the attendees will be notified on the dashboard. You can know more about meetings here',
      anchorTag: 'click here',
      link: 'https://support.flujo.io/apps/meetings',
      isOpen: false
    },
    {
      title: 'How do I monitor my Account Activity?',
      content: 'Navigate to Account activity section by clicking on settings icon on top right corner of your dashboard and then selecting account activity.',
      isOpen: false
    },
    {
      title: 'How do I change the roles of members of my workspace?',
      content: 'Only the members who have special privileges would be able to change the roles of the members of the workspace. Navigate to User Management section by clicking on administration section . Navigate to administration section by clicking on settings icon present on the top Right hand side.',
      isOpen: false
    },
    {
      title: 'How do I change the preferences of access levels given to various members?',
      content: 'Navigate to workspace level preferences section by clicking on administration section. Navigate to administration section by clicking on settings icon present on the top right hand side.',
      isOpen: false
    },
    {
      title: 'How do I check/change user permissions in a workspace?',
      content: 'Click on the Settings icon available at the top right corner of your Dashboard. Select Administration and select the User Management section. You will be able to view all the users and their roles in the workspace.',
      isOpen: false
    },
    {
      title: 'How do I manage Teams?',
      content: 'Click on the Settings icon available at the top right corner on your dashboard and select Team settings. You can view all the teams available in your workspace. Public and Private teams can be viewed in separate tabs. You can know more about teams here',
      anchorTag: 'click here',
      link: 'https://support.flujo.io/top-navigation-bar/team-settings',
      isOpen: false
    }
  ];

  categoriesList = [
    { name: 'Get Started', icon: 'navigation.svg' },
    { name: 'How to use FLUJO', icon: 'dashboard.svg' },
    { name: 'Features', icon: 'grid.svg' },
    { name: 'Profile & Preferences', icon: 'user-tick.svg' },
    { name: 'Workspace Administration', icon: 'key.svg' },
    { name: 'Pricing & Upgrade', icon: 'layers.svg' }
  ];
  categoriesListCopy: { name: string; icon: string; }[];
  constructor(private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    window.scrollTo(0, 0);
  }
    this.setMetaTags();
    this.categoriesListCopy = this.categoriesList;
  }
  setMetaTags() {
    this.seoService.updateTitle('flujoapp dedicated help centre');
    this.seoService.updateDescription
      ('Get all the answers to your doubts. Clear your confusion. Even we sometimes don’t have all the answers, that’s why we keep revising our Help center. So, keep checking back here for all updates.');
    this.seoService.updateOgUrl('https://www.flujo.io/help');
  }
  toggleMenu(item) {
    item.isOpen = !item.isOpen;
  }
  searchApp(term: string) {
    this.categoriesList = this.categoriesListCopy.filter(item => item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
  }
}
