import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { WindowSizeService } from 'src/app/_services/window-size.service';
import { DataCommunicationService } from 'src/app/_services/data-communication.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';

interface FeaturesCardsObject {
  activeId: number;
  items: CardItem[];
}
interface CardItem {
  id: number;
  name: string;
  icon: string;
  content: string;
  isMdIcon?: boolean;
}
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mobileVideo') mobileVideo: ElementRef;
  @ViewChild('featureDataBlock', { static: true }) featureDataBlock: ElementRef;
  activeTabId = 1;
  communicationItems: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'One-to-one chat', icon: 'message.svg', content: 'Get in touch with anyone within your organization instantly.' },
      { id: 5, name: 'Team chat', icon: 'teams.svg', content: 'From those impromptu wine and cheese parties to large projects with stakeholders across the globe — there can be a team for everything.' },
      // { id: 9, name: 'Team chat', icon: 'message.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      { id: 2, name: 'Guest users', icon: 'guest.svg', content: 'Bring external consultants, vendors and service providers to your conversations for seamless collaboration.' },
      { id: 3, name: 'Rich text formatting', icon: 'text-format.svg', content: 'Visually format your outgoing messages without having to remember shortcodes and special instructions.' },
      { id: 7, name: 'Threads', icon: 'threads.svg', content: 'Reduce noise in your teams by organising your discussions and easily access them at any point using threads.' },
      { id: 4, name: 'Mentions', icon: 'mentions.svg', content: 'Bring anyone into an ongoing discussion using <span class="red-clr">@mentions</span>.' },
      // { id: 6, name: 'My wall (Reminders - Alerts - To-do)', icon: 'my-wall.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      { id: 8, name: 'Search', icon: 'search.svg', content: 'Travel back and forth in time in an instant by accessing any information across your teams with our powerful search options.' },
    ]
  }
  callItems: FeaturesCardsObject = {
    activeId: 2,
    items: [
      { id: 2, name: '1-one-1 Audio & Video calling', icon: 'call-out.svg', content: 'Instant audio/video calls with anyone within your workspace with unlimited minutes — directly from your messenger.' },
      { id: 1, name: 'Video conferencing', icon: 'av.svg', content: 'Free and paid video conferencing support through deep integrations.' },
      { id: 3, name: 'Screen Sharing', icon: 'group-call.svg', content: ' Instantly share your screen with your teammates during your calls for easy collaboration.', isMdIcon: true },
    ]
  }
  pollsItems: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Instant Polling', icon: 'poll.svg', content: 'Run direct polls across your teams for quick feedback on literally anything. Include images, documents, presentations to your poll options for anyone to vote.' },
      // { id: 1, name: 'Private Polls', icon: 'av.svg', content: 'Run private polls across individual inboxes for discrete feedback collection.' },
      // { id: 2, name: 'Public Polling', icon: 'call-out.svg', content: 'Run polls publically across your teams.' },
      // { id: 3, name: 'Multimedia polls', icon: 'group-call.svg', content: 'Include images, documents, presentations or any other files in your poll options for collecting feedback.' }
    ]
  }
  driveCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Personal Drive', icon: 'p-drive.svg', content: 'Your personal space on the cloud. Store, access and share files and media without any limits.' },
      { id: 2, name: 'Team Drive', icon: 'hard-drive.svg', content: 'Say goodbye to filtering emails with attachments. Find all the files you exchange with your teammates in one place, in one click.' },
      { id: 3, name: 'Intelligent Organizer', icon: 'layers.svg', content: 'All the files you exchange in chats or teams are automatically organized in dedicated folders.' },
      { id: 6, name: 'Sharing and Import', icon: 'share.svg', content: 'Share files with your teams directly from your messenger and import files from third-party drives like Google Drive with our integrations.' },
      // { id: 4, name: '100 GB of space', icon: 'database.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      // { id: 5, name: 'Notes', icon: 'edit.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      // { id: 7, name: 'Download', icon: 'download.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' }
    ]
  }

  notesCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Organise', icon: 'layers.svg', content: 'Create folders to organize your notes.' },
      { id: 2, name: 'Auto-sync', icon: 'refresh-cw.svg', content: 'Every word you type automatically synced so that you never lose a note again.' },
      { id: 3, name: 'Share', icon: 'share.svg', content: 'Instantly share your notes with your teammates.' },
      { id: 5, name: 'Wikis', icon: 'wikis.svg', content: 'Create simple wikis for your teams—keep people who matter always updated.' },
      // { id: 4, name: 'Discuss', icon: 'discuss.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
    ]
  }
  kanbansCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Boards', icon: 'boards.svg', content: 'Create public, private and personal boards to manage your workflows.' },
      { id: 2, name: 'Templates', icon: 'template.svg', content: 'Choose from dozens of industry specific kanban templates' },
      // { id: 3, name: 'Archive', icon: 'archive.svg', content: 'Never lose sight of your old, completed projects with auto-archiving.' },
      { id: 4, name: 'Follow', icon: 'follow.svg', content: 'Get instant updates on what matters to you the most. Follow a board, list or a card and never miss a deadline.' },
      { id: 5, name: 'Activity', icon: 'discuss.svg', content: 'Catchup with the progress of any task even while you are away with activity logs.' },
      // { id: 5, name: 'Comments', icon: 'discuss.svg', content: 'Let your teams log every activity on a card with comments.' },
      // { id: 6, name: 'Checklists', icon: 'check-list.svg', content: 'Do not let anything slip through the cracks with dedicated checklists on every kanban card.' },
      { id: 7, name: 'Teams', icon: 'teams.svg', content: 'Create boards and assign cards directly to entire teams.' },
      { id: 8, name: 'Labels', icon: 'labels.svg', content: 'Categorise cards & boards with labels and use them in filters, making it easier to find the things you need.' },
      { id: 9, name: 'Advance search', icon: 'search.svg', content: 'Navigating across multiple boards and accessing information made simple with advanced search options.' },
    ]
  }
  meetingsCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Schedule', icon: 'calendar.svg', content: 'Create and invite individuals or entire teams to meetings instantly' },
      { id: 2, name: 'Alerts', icon: 'alerts.svg', content: 'Timely smart alerts sent straight to your messenger' },
      { id: 3, name: 'Minutes', icon: 'discuss.svg', content: 'Never carry a notepad to meetings again. Capture minutes of your meetings directly into an invite, for your entire team to see.' },
      { id: 4, name: 'Auto-scheduling', icon: 'clock.svg', content: 'Share a public link to your calendar for anyone to directly book an appointment with you in a pre-defined slot.' },
    ]
  }
  tasksCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Create', icon: 'plus-circle.svg', content: 'Create tasks for yourself and always stay on top of your deadlines' },
      { id: 2, name: 'Delegate', icon: 'user-tick.svg', content: 'Quickly assign tasks to an individual or an entire team' },
      { id: 3, name: 'Tracking and Dashboard', icon: 'track.svg', content: 'Track activity and progress of your tasks in real time on the tasks dashboard.' },
      // { id: 4, name: 'Dashboard', icon: 'dashboard.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      { id: 5, name: 'Smart Alerts', icon: 'alerts.svg', content: 'Receive alerts on the status, due-dates and deadlines of your tasks directly in your messenger.' },
      { id: 6, name: 'Comments', icon: 'discuss.svg', content: 'Let your teams log every activity on task with comments.' },
    ]
  }
  calendarCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'My wall', icon: 'my-wall.svg', content: 'Your goto place for important updates from all the apps' },
      { id: 2, name: 'Periscope', icon: 'periscope.svg', content: 'See things for the day that may be otherwise out of sight—your meetings, deadlines, events and more in one single hub.' },
      // { id: 3, name: 'Share a link', icon: 'link.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
      // { id: 4, name: 'Discuss', icon: 'discuss.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
    ]
  }
  botsCards: FeaturesCardsObject = {
    activeId: 1,
    items: [
      { id: 1, name: 'Reminders', icon: 'clock.svg', content: `Set reminders about anything and let tac nudge you when it's time.` },
      { id: 2, name: 'Requests', icon: 'repeat.svg', content: 'Actions that need your attention brought straight to your wall.' },
      { id: 3, name: 'Alerts', icon: 'alerts.svg', content: 'Tac loves playing fetch—sit back and relax while he brings all the important updates directly to you. Tac gets smarter everyday as he learns more and more about your preferences.' },
      // { id: 4, name: 'Bot - loremipsum', icon: 'discuss.svg', content: 'Collaborate as you communicate at work. Experience the real power of getting work done with our Messenger.' },
    ]
  }
  isFeatureTabSticky: boolean;
  activeTabObserver: Subscription;
  isMobileView: boolean;
  constructor(private sizeService: WindowSizeService, private dcService$: DataCommunicationService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }
  @HostListener('window:scroll', ['$event.target'])
  onScroll() {
    this.sizeListener();
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.activeTabListener();
    this.seoService.updateTags('features');
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
  ngOnDestroy() {
    if(this.activeTabObserver){
      this.activeTabObserver.unsubscribe();
    }
    this.dcService$.$activeFeature.next(null);
  }
  changeTab(id, event?) {
    this.activeTabId = id;
    if (window.innerWidth < 601) {
      // const scrollEle = event.target.closest('.tabs--header');
      const scrollEle = document.querySelector('.tabs--header');
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 356);
      }
      if (id === 1) {
        scrollEle.scrollLeft = 0;
      } else if (id === 2) {
        scrollEle.scrollLeft = scrollEle.scrollWidth;
      } else if (id === 3) {
        scrollEle.scrollLeft = scrollEle.scrollWidth;
      }
    }
  }
  showContent(obj: FeaturesCardsObject, item: CardItem) {
    if (obj.activeId !== item.id) {
      obj.activeId = item.id;
    }
  }

  onVisibilityChanged(entry) {
    try {
      if (entry.isIntersecting) {
        // entry.target.currentTime = 0;
        // entry.target.play();
        this.mobileVideo.nativeElement.play();
      } else {
        // entry.target.pause();
        this.mobileVideo.nativeElement.pause();
      }
    } catch (error) {
        console.log(error);
    }
  }

  sizeListener() {
    this.sizeService.$mobileView.subscribe(res => {
      this.isMobileView = res;
      if (res) {
        const topGap = this.featureDataBlock.nativeElement.getBoundingClientRect()
        // if (topGap.y <= 64 && topGap.y > 100 - topGap.height) {
        if (topGap.y <= 64) {
          this.isFeatureTabSticky = true;
        } else {
          this.isFeatureTabSticky = false;
        }
      } else {

      }
    })
  }

  activeTabListener() {
    this.activeTabObserver = this.dcService$.$activeFeature.subscribe(res => {
      if (res) {
        try {
          setTimeout(() => {
            this.changeTab(res);
            const element = document.querySelector('.tabs--header');
            const headerOffset = 110;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
              });
            }
          }, 10);

        } catch (error) {
          console.log(error);
        }
      }
    })
  }


}
