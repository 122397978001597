import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from 'src/app/seo.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-app-directory',
  templateUrl: './app-directory.component.html',
  styleUrls: ['./app-directory.component.scss']
})
export class AppDirectoryComponent implements OnInit {
  integrations = [
    { name: 'Google Drive', icon: 'google.png' },
    { name: 'Unsplash', icon: 'unsplash.svg' },
    { name: 'GitHub', icon: 'github.png' },
    { name: 'Jenkins', icon: 'jenkins.png' },
    { name: 'Bitbucket', icon: 'bitbucket.png' },
    { name: 'Jitsi', icon: 'jitsi.png' },
  ]
  integrationsCopy = [];
  constructor(private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.integrationsCopy = this.integrations;
    this.seoService.updateTags('appdirectory');
  }

  searchApp(term: string) {
    this.integrations = this.integrationsCopy.filter(item => item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
  }

}
